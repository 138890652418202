var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _vm.uploadStatus === "unfinished"
        ? _c("p", { staticClass: "text-left mb-0 mr-1" }, [
            _vm._v(
              "Reset before uploading a csv file for " +
                _vm._s(_vm.entity) +
                "."
            ),
          ])
        : _c("p", { staticClass: "text-left mb-0 mr-1" }, [
            _vm._v(
              "A csv file for " +
                _vm._s(_vm.entity) +
                " has been successfully uploaded."
            ),
          ]),
      _c("v-spacer"),
      _vm.uploadStatus === "done"
        ? _c(
            "v-btn",
            {
              attrs: { "x-small": "", loading: _vm.loading },
              on: { click: _vm.deleteCsv },
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { "x-small": "" } }, [
                _vm._v("mdi-delete"),
              ]),
              _vm._v(" Delete file "),
            ],
            1
          )
        : _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: { "x-small": "", color: "#00aadb", loading: _vm.loading },
              on: { click: _vm.deleteCsv },
            },
            [_vm._v(" Reset ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }