// Sort string alphabetically and numerically (A1, B1, B2...)
export const sortAlphaNum = (a, b) => {
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;
  const aA = a.replace(reA, "");
  const bA = b.replace(reA, "");
  if (aA === bA) {
    const aN = parseInt(a.replace(reN, ""), 10);
    const bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
}

// Generate non existing number string
export const generateRandomId = (existingIds) => {
  const existingNumberIds = existingIds.filter(id => Number.isInteger(parseInt(id)));
  if (existingNumberIds.length === 0) return "1"
  const highestId = Math.max(...existingNumberIds);
  return (highestId + 1).toString();
}