<template>
  <v-app-bar app dense elevation="4" color="#fff">
    <v-app-bar-nav-icon @click="$emit('show-sidebar')"></v-app-bar-nav-icon>
    <v-toolbar-title class="d-flex align-center">
      <v-icon color="black" class="mr-2">mdi-{{ iconName }}</v-icon>
      <span class="title">{{ $route.name }}</span>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",
  props: { sidebar: Boolean },
  computed: {
    iconName() {
      switch (this.$route.path) {
        case "/setup":
          return "cogs"
        case "/migration":
          return "play-pause"
        case "/history":
          return "clipboard-text-clock"
        case "/profile":
          return "account"
        default:
          return "cogs"
      }
    }
  }
}
</script>

<style scoped>
.title {
  color: black;
}
</style>