<template>
  <v-card>
    <v-card-title>Your information</v-card-title>
    <v-card-text>
      <v-text-field
        label="Email"
        v-model="email"
        disabled
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      email: this.$auth.user.email,
    }
  },
}
</script>

<style scoped lang="scss">
.setup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .v-card {
    text-align: center;
    width: 750px;

    img {
      width: 100%;
    }
  }
}
</style>