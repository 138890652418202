// eslint-disable-next-line no-unused-vars
import { addDoc, collection, getFirestore, getDoc, getDocs, doc, updateDoc, onSnapshot } from "firebase/firestore"; 
import { USERS_COLLECTION_NAME } from "./model.user"; 
import store from "../../store";
import { db } from "../../service/firebaseConfig";
import { ALL_ENTITIES } from "../../constants"

export const MIGRATION_SCHEMA_VERSION  = '0.0.1'
export const MIGRATION_COLLECTION_NAME  = 'migration'

/**
 * Create a migration.
 * @param {String} user_id - Id use to identify the user. SHould be the same as the one in the auth
 * @param {Object} migration_name - Migration object.
 */
export async function createMigration(user_id, { source_snapshot, destination_snapshot }, hasEntitiesSelectionPermission){
    const migration_col = collection(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME)

  let entities = {};
  if (!hasEntitiesSelectionPermission) {
    entities = ALL_ENTITIES
    if (source_snapshot.integration.name === "quickbooks") delete entities.gift_cards
  }

    let new_migration = {
      '_nkdv_': MIGRATION_SCHEMA_VERSION,
      'migration_number': null,
      source_snapshot,
      destination_snapshot,
      location_mapping: [],
      entities,
      progress: {
        average_time: 0,
        count_converted: 0,
        total: 0,
        count_fetch: 0,
        count_push: 0,
        progress_status: ""
      },
      state: {
        control_step: "",
        current_entity: "",
        global_step: "CONFIG",
        sub_step: ""
      }
    }
    
    const migration_response = await addDoc(
      migration_col,
      new_migration
    )

    const user_col = doc(db, USERS_COLLECTION_NAME, user_id)

    await updateDoc(user_col, { 
      'last_migration.is_running':false,
      'last_migration.migration':migration_response.id,
      'last_migration.migration_state':""
    })

    return await getDoc(migration_response)
}

/**
 * Update a migration.
 * @param {String} user_id - Id use to identify the user.
 * @param {Object} updatedMigration - Name of the integration. Currently handle resto_l or resto_k
 */
export async function updateMigration(user_id, col_id, updatedMigration){
  const migration_col = doc(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, col_id)

  return await updateDoc(migration_col, updatedMigration)

}

/**
 * Get a migration.
 * @param {String} user_id - Id use to identify the user.
 * @param {{account_name:string, locations:[{id:string,name:string,country:string, timezone:string, currency_code:string}] 
 * }} updatedIntegration - Name of the integration. Currently handle resto_l or resto_k
 */
export async function getMigration(user_id, col_id){


  const migration_col = doc(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, col_id)

  return await getDoc(migration_col)

}

/**
 * Get all migrations of a user.
 * @param {String} user_id - ID used to identify the user.
 */
export async function getAllMigrations(user_id){
  const migration_col = collection(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME)

  const querySnapshot = await getDocs(migration_col)
  
  const migrations = []

  querySnapshot.forEach((doc) => {
      const data = doc.data()

      migrations.push(
          {
              'id': doc.id,
              ...data
          }
      )
  })

  return migrations
}

/**
 * Listen to changes in the migration collection
 * @param {String} user_id use to identify the user. Should be the same as the one in the auth
 * @param {String} migration_id use to identify the migration.
 */
export function listenMigration(user_id, migration_id) {
  return onSnapshot(
    doc(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, migration_id),
    (doc) => store.dispatch("setFirestoreMigration", doc.data()),
    error => console.log("Error on listen ->", error) // TODO: What error handling to do, if any?
  )
}  