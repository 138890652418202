var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "0" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pb-0" },
        [
          _c("div", { class: "mb-" + (_vm.hasLocationsMapping ? "6" : "0") }, [
            _vm._v("Map "),
            _c("b", [_vm._v("locations")]),
            _vm._v(" between your source and destination accounts:"),
          ]),
          _c(
            "v-alert",
            {
              staticClass: "mb-6",
              attrs: {
                value: !_vm.hasLocationsMapping,
                dense: "",
                text: "",
                type: "error",
                transition: "slide-x-transition",
              },
            },
            [_vm._v("You must have at least one locations mapping saved.")]
          ),
          _vm._l(_vm.mapping, function (map, index) {
            return _c(
              "v-row",
              { key: index },
              [
                _c(
                  "v-col",
                  { staticClass: "py-0" },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.locations.source,
                        disabled: _vm.step !== 1,
                        "return-object": "",
                        outlined: "",
                        dense: "",
                      },
                      model: {
                        value: map.source,
                        callback: function ($$v) {
                          _vm.$set(map, "source", $$v)
                        },
                        expression: "map.source",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0", attrs: { cols: "2" } },
                  [
                    _c(
                      "v-icon",
                      { attrs: { size: "30", disabled: _vm.step !== 1 } },
                      [_vm._v("mdi-arrow-right-bold")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "py-0" },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.locations.destination,
                        disabled: _vm.step !== 1,
                        "return-object": "",
                        outlined: "",
                        dense: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.hasMultiLocationsMappingPermission
                            ? {
                                key: "append-outer",
                                fn: function () {
                                  return [
                                    _vm.step === 1
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled:
                                                _vm.mapping.length === 1,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeMapping(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-delete"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: map.destination,
                        callback: function ($$v) {
                          _vm.$set(map, "destination", $$v)
                        },
                        expression: "map.destination",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "v-card-actions",
        { staticClass: "pt-2 pb-5" },
        [
          _c("v-spacer"),
          _vm.step === 1
            ? _c(
                "div",
                [
                  _vm.hasMultiLocationsMappingPermission
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-3",
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.changeMapping(null)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-plus")]
                          ),
                          _vm._v(" Add location mapping "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        color: "#00aadb",
                        small: "",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.saveMapping },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-content-save")]
                      ),
                      _vm._v(" Save locations settings "),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }