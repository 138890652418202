var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: "3000", color: _vm.type, text: "", elevation: "10" },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function (ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("v-icon", { attrs: { color: _vm.type } }, [
            _vm._v(
              _vm._s(_vm.type === "success" ? "mdi-check" : "mdi-alert-octagon")
            ),
          ]),
          _c("span", { staticClass: "ml-3" }, [_vm._v(_vm._s(_vm.text))]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }