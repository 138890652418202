export const ALL_ENTITIES = {
  items: {
    to_migrate: true,
    entity_name: "items",
    include_archived: true,
    identifier: "sku",
    start_date: "",
    end_date: "",
    state: {
      convert: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: ""
      },
      fetch: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      push: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      is_migration_done: false
    }
  },
  customers: {
    to_migrate: true,
    entity_name: "customers",
    include_archived: true,
    identifier: "email",
    start_date: "",
    end_date: "",
    state: {
      convert: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: ""
      },
      fetch: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      push: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      is_migration_done: false
    }
  },
  sales: {
    to_migrate: true,
    entity_name: "sales",
    include_archived: true,
    identifier: "",
    start_date: "",
    end_date: "",
    state: {
      convert: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: ""
      },
      fetch: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      push: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      is_migration_done: false
    }
  },
  vendors: {
    to_migrate: true,
    entity_name: "vendors",
    include_archived: true,
    identifier: "",
    start_date: "",
    end_date: "",
    state: {
      convert: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: ""
      },
      fetch: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      push: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      is_migration_done: false
    }
  },
  gift_cards: {
    to_migrate: true,
    entity_name: "gift_cards",
    include_archived: false,
    identifier: "",
    start_date: "",
    end_date: "",
    state: {
      convert: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: ""
      },
      fetch: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      push: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      is_migration_done: false
    }
  },
  credit_accounts: {
    to_migrate: true,
    entity_name: "credit_accounts",
    include_archived: false,
    identifier: "",
    start_date: "",
    end_date: "",
    state: {
      convert: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: ""
      },
      fetch: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      push: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      is_migration_done: false
    }
  },
  inventory: {
    to_migrate: true,
    entity_name: "inventory",
    include_archived: true,
    identifier: "",
    start_date: "",
    end_date: "",
    state: {
      convert: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: ""
      },
      fetch: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      push: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      is_migration_done: false
    }
  },
  images: {
    to_migrate: true,
    entity_name: "images",
    include_archived: false,
    identifier: "",
    start_date: "",
    end_date: "",
    state: {
      convert: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: ""
      },
      fetch: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      push: {
        average_time: 0,
        count_done: 0,
        count_error: 0,
        count_total: 0,
        is_done: false,
        last_migration_id: "",
      },
      is_migration_done: false
    }
  },
}