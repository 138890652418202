// eslint-disable-next-line no-unused-vars
import { collection, getDocs, doc, updateDoc, onSnapshot, query } from "firebase/firestore"; 
// import {   arrayUnion, arrayRemove } from "firebase/firestore"; 
import { USERS_COLLECTION_NAME } from "./model.user"; 
import store from "../../store";
import { db } from "../../service/firebaseConfig";

export const INTEGRATION_SCHEMA_VERSION  = '0.0.1'
export const INTEGRATION_COLLECTION_NAME  = 'integration'


/**
 * Create a user.
 * @param {String} user_id - Id use to identify the user.
 * @param {{account_name:string, locations:[{id:string,name:string,country:string, timezone:string, currency_code:string}] 
 * }} updatedIntegration - Name of the integration. Currently handle resto_l or resto_k
 */
export async function updateIntegration(user_id, col_id, updatedIntegration){

    const integration_col = doc(db, USERS_COLLECTION_NAME, user_id, INTEGRATION_COLLECTION_NAME, col_id)

    return await updateDoc(integration_col,updatedIntegration)

}

/**
 * Get all integration of the log in user.
 * @param {String} user_id - Id use to identify the user.
 */
export async function getAllIntegration(user_id){

    const integration_col = collection(db, USERS_COLLECTION_NAME, user_id, INTEGRATION_COLLECTION_NAME)

    // integration_col
    // await updateDoc(integration_col,updatedIntegration)

    const querySnapshot = await getDocs(integration_col)
    
    const allIntegration = []

    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data()

        allIntegration.push(
            {
                'id': doc.id,
                ...data
            }
        )
    })

    return allIntegration
}

/**
 * Listen to changes in the integration collection
 * @param {String} user_id use to identify the user.
 */
 export function listenIntegration(user_id) {
     
    return onSnapshot(
        query(collection(db, USERS_COLLECTION_NAME, user_id, INTEGRATION_COLLECTION_NAME)),
        (docs) => {
            const integrations = [];
            docs.forEach(doc => {
                integrations.push({ ...doc.data(), id: doc.id })
            })
            store.dispatch("setFirestoreIntegrations", integrations)
        },
        error => console.log("Error on listen ->", error) // TODO: What error handling to do, if any?
    )
  }