<template>
    <div>
        <!-- <v-divider class="mt-4"></v-divider> -->
        <v-card elevation="0">
            <!-- Title -->
            <!-- <v-card-title class="subtitle-1"
                >Manage your tax settings:</v-card-title
            > -->

            <v-card-text class="pb-0">
                <!-- Tax inclusive -->
                <!-- <v-checkbox
                    class="mt-0"
                    v-model="isTaxInclusive"
                    label="Tax inclusive"
                    :disabled="!editable"
                ></v-checkbox> -->

                <!-- Multiple rates -->
                <!-- <v-checkbox
                    v-if="integrationName === 'csv'"
                    class="mt-0"
                    v-model="hasMultipleRates"
                    label="Multiple rates"
                    :disabled="!editable"
                ></v-checkbox> -->

                <!-- Tax rates -->
                <!-- <v-form v-if="integrationName === 'csv'" v-model="valid">
          <div class="d-flex" v-for="(tax, index) in taxRates" :key="index">
            <v-text-field 
              class="mr-2 shrink"
              label="Tax ID"
              v-model="tax.id"
              :disabled="!editable"
              :rules="[rules.required, checkDuplicate(index)]"
              outlined
              dense
            >
            </v-text-field>
            <v-text-field 
              class="mr-2 shrink"
              label="Tax name" 
              v-model="tax.name" 
              :disabled="!editable"
              :rules="[rules.required]"
              outlined 
              dense
            >
            </v-text-field>
            <v-text-field 
              class="shrink"
              label="Rate 1" 
              v-model="tax.rate_1" 
              :disabled="!editable"
              :rules="[rules.required, rules.number, rules.decimals]"
              outlined 
              dense
            >
              <template v-slot:append-outer v-if="!hasMultipleRates">
                <v-btn icon :disabled="taxRates.length === 1" @click="changeTaxRates(index)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
            </v-text-field>
            <v-text-field 
              class="ml-2 shrink"
              v-if="hasMultipleRates"
              label="Rate 2" 
              v-model="tax.rate_2" 
              :disabled="!editable"
              :rules="[rules.required, rules.number, rules.decimals]"
              outlined 
              dense
            >
              <template v-slot:append-outer>
                <v-btn icon :disabled="taxRates.length === 1" @click="changeTaxRates(index)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
            </v-text-field>
          </div>
        </v-form> -->
            </v-card-text>

            <!-- Add / Save / Edit buttons -->
            <!-- <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <div v-if="editable">
          <v-btn 
            v-if="integrationName === 'csv'" 
            class="mr-3" 
            small 
            @click="changeTaxRates(null)"
          >
            <v-icon class="mr-1" small>mdi-plus</v-icon>
            Add a tax rate
          </v-btn>
          <v-btn 
            class="white--text" 
            color="#00aadb" 
            small 
            :loading="loading" 
            :disabled="!valid" 
            @click="save"
          >
            <v-icon small class="mr-1">mdi-content-save</v-icon>
            Save tax settings
          </v-btn>
        </div>
        <v-btn v-else small :disabled="isEditDisabled" @click="editable = true"><v-icon small class="mr-1">mdi-pencil</v-icon>Edit tax settings</v-btn>
      </v-card-actions> -->
        </v-card>
    </div>
</template>

<script>
import { updateIntegration } from "../../database/model/model.integration";
import { generateRandomId } from "../../service/helpers";

export default {
    name: "TaxSettings",
    props: { integrationName: String },
    data() {
        return {
            editable: false,
            isTaxInclusive: null,
            hasMultipleRates: null,
            taxRates: [
                {
                    id: "1",
                    name: "",
                    rate_1: "",
                    rate_2: "",
                },
            ],
            loading: false,
            valid: true,
            rules: {
                required: (value) => !!value || "Required.",
                number: (value) =>
                    (typeof value === "string" && !isNaN(value)) ||
                    "Must be a number.",
                decimals: (value) => {
                    if (!value.includes(".")) return true;
                    else
                        return (
                            value.split(".")[1].length <= 3 ||
                            "Must have 3 decimals maximum"
                        );
                },
            },
        };
    },
    computed: {
        // Return the csv integration
        integration() {
            return this.$store.state.firestore.integrations.find(
                (integration) => integration.name === this.integrationName
            );
        },
        // Return if there's a location in the integration
        isEditDisabled() {
            return this.integration.locations.length === 0;
        },
        // Return an array of the ids being used
        existingIds() {
            return this.taxRates.map((tax) => tax.id);
        },
    },
    watch: {
        // Set initial tax settings everytime a different integration is selected
        integrationName() {
            this.setTaxSettings();
        },
        // If no multiple rates, empties rate_2
        hasMultipleRates(isTrue) {
            if (!isTrue) {
                this.taxRates = this.taxRates.map((taxRate) => {
                    return {
                        ...taxRate,
                        rate_2: "",
                    };
                });
            }
        },
    },
    methods: {
        // Set properties to their default/initial values
        setTaxSettings() {
            const locationWithTaxSettings = this.integration.locations.find(
                (location) => location.tax_settings
            );
            if (locationWithTaxSettings) {
                const { inclusive, multiple_rates, tax_list } =
                    this.integration.locations[0].tax_settings;
                this.hasMultipleRates = multiple_rates;
                this.isTaxInclusive = inclusive;
                if (tax_list && tax_list.length > 0) {
                    this.taxRates = tax_list.map((taxRate) => {
                        return {
                            ...taxRate,
                            rate_1: (
                                Math.round(taxRate.rate_1 * 100 * 1000) / 1000
                            ).toString(),
                            rate_2: (
                                Math.round(taxRate.rate_2 * 100 * 1000) / 1000
                            ).toString(),
                        };
                    });
                } else {
                    this.taxRates = [
                        {
                            id: "1",
                            name: "",
                            rate_1: "",
                            rate_2: "",
                        },
                    ];
                }
            } else {
                this.isTaxInclusive = null;
                this.hasMultipleRates = null;
                this.taxRates = [
                    {
                        id: "1",
                        name: "",
                        rate_1: "",
                        rate_2: "",
                    },
                ];
            }
        },
        // Add or delete tax in the data
        changeTaxRates(index) {
            if (Number.isInteger(index)) {
                this.taxRates.splice(index, 1);
            } else {
                this.taxRates.push({
                    id: generateRandomId(this.existingIds),
                    name: "",
                    rate_1: "",
                    rate_2: "",
                });
            }
        },
        // Save tax_rate settings in firebase
        async save() {
            this.loading = true;
            try {
                const firestore = this.$store.state.firestore;

                const { id: integrationId } = this.integration;

                let tax_settings = {
                    inclusive: this.isTaxInclusive,
                };

                if (this.integrationName === "csv") {
                    const rates = this.taxRates.map((taxRate) => {
                        return {
                            ...taxRate,
                            rate_1: (taxRate.rate_1 * 1000) / 100000,
                            rate_2: (taxRate.rate_2 * 1000) / 100000,
                        };
                    });
                    tax_settings = {
                        ...tax_settings,
                        multiple_rates: this.hasMultipleRates,
                        tax_list: rates,
                    };
                }

                const { locations } = this.integration;
                const locationsWithTaxSettings = locations.map((location) => {
                    return {
                        ...location,
                        tax_settings,
                    };
                });
                await updateIntegration(firestore.id, integrationId, {
                    locations: locationsWithTaxSettings,
                });

                const newIntegration = {
                    ...this.integration,
                    locations: locationsWithTaxSettings,
                };
                const allIntegrations = [
                    ...this.$store.state.firestore.integrations.filter(
                        (integration) =>
                            integration.name !== this.integrationName
                    ),
                    newIntegration,
                ];
                this.$store.dispatch(
                    "setFirestoreIntegrations",
                    allIntegrations
                );

                this.editable = false;
                this.$store.dispatch("setSnackbar", {
                    type: "success",
                    text: `Tax settings saved.`,
                });
            } catch (error) {
                console.log("save, error ->", error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: `Something went wrong. Please try again.`,
                });
            } finally {
                this.loading = false;
            }
        },
        // Check if ID already exists among other IDs
        checkDuplicate(index) {
            const value = this.taxRates[index].id;
            const otherIds = [
                ...this.existingIds.slice(0, index),
                ...this.existingIds.slice(index + 1),
            ];
            if (otherIds.includes(value)) {
                return "ID already exists.";
            } else {
                return true;
            }
        },
    },
    created() {
        this.setTaxSettings();
    },
};
</script>

<style></style>
