<template>
  <div>
      <div v-if="showAdmin()">
        <v-card class="center-flex">
          <v-card-title>Placeholder</v-card-title>
          <v-card-text>
            <v-text-field
              label="Registration key"
              v-model="newRegistrationKey"
              
            ></v-text-field>
          </v-card-text>
          <v-btn class="white--text" color="#00aadb" :loading="fetchKeyLoading" @click="getNewKey">Generate a registration key</v-btn>
        </v-card>
      </div>

      <v-container class="center-flex" v-else>
        <h1>Unauthorized Access</h1>
      </v-container>
  </div>
</template>

<script>
import { getInstance } from "../auth";
import { DoosyncMainApi } from "../service/main_api";
import { actions, roles } from "../constants"
import hasPermission from "../service/permissions"

export default {
  name: "Profile",
  data() {
    return {
      newRegistrationKey: '',
      fetchKeyLoading: false
    }
  },
  methods: {
    showAdmin(){
      return hasPermission(this.$auth.user, actions.ADMIN_PAGE)
    },
    async getNewKey(){
      const api = new DoosyncMainApi(getInstance())
      this.fetchKeyLoading = true
      // TODO: add premission choice
      try {
        const response = await api.createRegistrationKey([roles.ADMIN]);
        this.newRegistrationKey = response.data.data.id
      } catch (error) {
        this.newRegistrationKey = "FAIL CONTECT DEV"
      }      
      this.fetchKeyLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.setup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .v-card {
    text-align: center;
    width: 750px;

    img {
      width: 100%;
    }
  }
}

div .center-flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>