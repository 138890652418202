<template>
  <v-dialog
    v-model="show"
    max-width="500"
    persistent
  >
  <v-card>
    <v-card-title>Confirmation required</v-card-title>

    <v-card-text>{{ text }}</v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="show = false">Go back</v-btn>
      <v-btn
        :color="buttonColor"
        :class="{ 'white--text': buttonColor === '#00aadb' }"
        @click="$emit('confirm')"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ActionConfirmation",
  props: {
    text: String,
    buttonText: String,
    buttonColor: String,
    open: Boolean
  },
  computed: {
    show: {
      get() {
        return this.open
      },
      set() {
        this.$emit('back')
      }
    }
  }
}
</script>

<style>

</style>