<template>
  <v-card class="mt-5 d-flex flex-column">
    <v-card-title class="justify-center">2. Start the migration</v-card-title>

    <div v-if="step === 2">
      <!-- Start new button -->
      <v-card-text v-if="showButtons === 'new'">
        <v-btn dark @click="createNewMigration" :loading="loading.new">Start new migration</v-btn>
      </v-card-text>

      <!-- Pause / Resume / Cancel buttons -->
      <v-card-text v-else-if="showButtons === 'pause/cancel/resume'">
        <v-btn 
          v-if="status === 'PAUSED' && canResume" 
          class="mr-5" 
          dark 
          :loading="loading.resume"
          @click="migrationAction('resume')" 
        >
          <v-icon>mdi-play</v-icon>Resume
        </v-btn>

        <v-btn 
          v-else-if="canPause" 
          color="warning" 
          class="mr-5" 
          @click="migrationAction('pause')" 
          :loading="loading.pause"
        >
          <v-icon>mdi-pause</v-icon>Pause
        </v-btn>

        <v-btn 
          v-if="canCancel" 
          color="error" 
          @click="cancelMigration" 
          :loading="loading.cancel"
        >
          <v-icon>mdi-close-thick</v-icon>Cancel
        </v-btn>
      </v-card-text>

      <!-- Start and Start Daily Top-ups buttons -->
      <v-card-text v-else-if="!dailyTopUps">
        <v-btn dark @click="showConfirm = true" :loading="loading.start">Start migration</v-btn>
        <!-- TODO (TOPUPS): Uncomment or remove when decided so -->
        <!-- <span class="mx-5">OR</span>
        <v-btn dark @click="dailyTopUps = true">Start migration and daily top-ups</v-btn> -->
      </v-card-text>

      <!-- Daily top-ups setup -->
      <!-- TODO (TOPUPS): Uncomment or remove when decided so -->
      <!-- <v-card-text v-else>
        <div class="text-justify">You chose to start a migration an daily top-ups. This means that each day, DOOSYNC will automatically start a top-up migration from your source account to your destination account. Newly added records will be created and modified records will be updated.</div>
        <div class="text-justify mt-3">Select a date when the <strong>daily top-up migration will stop:</strong></div>
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="date-timezone my-0"
              v-model="date"
              hint="YYYY-MM-DD"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              Close
            </v-btn>
          </v-date-picker>
        </v-menu>
        <div class="text-justify mt-3">Select the time of the day where <strong>the daily top-up migration will automatically   start:</strong></div>
        <div class="text-justify d-flex">
          <vue-timepicker format="hh:mm A"></vue-timepicker>
          <v-select label="Timezone" outlined class="ml-5 date-timezone"></v-select>
        </div>
        <div class="d-flex justify-space-around align-center">
          <v-btn class="back" text x-small @click="dailyTopUps = false"><v-icon>mdi-arrow-left-thin</v-icon> Go back</v-btn>
          <v-btn dark>Confirm and start the migration</v-btn>
        </div>
      </v-card-text> -->
    </div>
    <ActionConfirmation
        text="Are you sure you're ready to migrate? The decision to start a migration is final."
        button-text="Start migration"
        button-color="#00aadb"
        :open="showConfirm"
        @back="showConfirm = false"
        @confirm="migrationAction('start')"
      />
  </v-card>
</template>

<script>
// TODO (TOPUPS): Uncomment or remove when decided so
// import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import { getInstance } from "../../auth";
import { DoosyncMainApi } from "../../service/main_api";
import { updateUser } from "../../database/model/model.user";
import { getAllIntegration } from "../../database/model/model.integration";
import { actions } from "../../constants"
import hasPermission from "../../service/permissions"
import ActionConfirmation from "../ui/ActionConfirmation.vue"

export default {
  name: "Controls",
  components: { ActionConfirmation },
  props: { step: Number, status: String },
  // TODO (TOPUPS): Uncomment or remove when decided so
  // components: { VueTimepicker },
  data() {
    return {
      // TODO (TOPUPS): Uncomment or remove when decided so
      dailyTopUps: false,
      // dateMenu: false,
      // date: "",
      showConfirm: false,
      loading: {
        start: false,
        pause: false,
        resume: false,
        cancel: false,
        new: false,
      },
    }
  },
  computed: {
    // Return if user has permission to pause migration
    canPause() {
      return hasPermission(this.$auth.user, actions.PAUSE_MIGRATION)
    },
    // Return if user has permission to resume migration
    canResume() {
      return hasPermission(this.$auth.user, actions.RESUME_MIGRATION)
    },
    // Return if user has permission to cancel migration
    canCancel() {
      return hasPermission(this.$auth.user, actions.CANCEL_MIGRATION)
    },
    // Return which buttons to display depending of the migration status
    showButtons() {
      switch (this.status) {
        case "QUEUED":
        case "RUNNING":
        case "PAUSED":
          return "pause/cancel/resume"
        
        case "CANCELLED":
        case "ERROR":
        case "DONE":
          return "new"

        default:
          return "start"
      }
    }
  },
  methods: {
    // Pause migration if running and creates a new one
    async cancelMigration() {
      this.loading.cancel = true;
      try {
        if (this.status === 'RUNNING' || this.status === 'QUEUED') {
          const api = new DoosyncMainApi(getInstance());
          await api.postMigrationAction('pause', this.$store.state.firestore.user.last_migration.migration);
        }

        setTimeout(async () => {
          await this.createNewMigration()
          this.$store.dispatch("setSnackbar", { type: "success", text: `Migration cancelled.` })
        }, 1050);

      } catch (error) {
        console.log("cancelMigration, error ->", error)
        this.$store.dispatch("setSnackbar", { type: "error", text: `Something went wrong.` })
      } finally {
        this.loading.cancel = false;
      }
    },
    // Update integrations and reset user.last_migration values in Firebase, firestore.migration in state and entities/locations in Configuration component
    async createNewMigration() {
      this.loading.new = true;
      try {
        const integrations = await getAllIntegration(this.$store.state.firestore.id);
        this.$store.dispatch("setFirestoreIntegrations", integrations);

        await updateUser({ 'last_migration.migration': null, 'last_migration.migration_state': null }, this.$store.state.firestore.id);
        this.$store.dispatch('setFirestoreMigration', null);
        this.$emit("reset");

        this.$store.dispatch("setSnackbar", { type: "success", text: `New migration is ready.` })
      } catch (error) {
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
        console.log("createNewMigration, error ->", error)
      } finally {
        this.loading.new = false;
      }
    },
    // Start, Pause or Resume a migration
    // 3 types (String): "start", "pause", "resume"
    async migrationAction(type) {
      this.showConfirm = false;
      this.loading[type] = true;
      try {
        const api = new DoosyncMainApi(getInstance());
        await api.postMigrationAction(type, this.$store.state.firestore.user.last_migration.migration);

        if (type === 'start') {
          this.$store.dispatch("setSnackbar", { type: "success", text: `Migration started.` })
        } else {
          this.$store.dispatch("setSnackbar", { type: "success", text: `Migration ${type}d.` })
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", { type: "error", text: `Could not ${type} migration. Please try again.` });
        console.log("error ->", error)
      } finally {
        this.loading[type] = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .v-card {
    text-align: center;
    width: 750px;

    .v-btn {
      background-color: #00aadb;
      &.back {
        background-color: transparent;
      }
    }
  }
</style>