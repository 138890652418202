var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Confirmation required")]),
          _c("v-card-text", [_vm._v(_vm._s(_vm.text))]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("Go back")]
              ),
              _c(
                "v-btn",
                {
                  class: { "white--text": _vm.buttonColor === "#00aadb" },
                  attrs: { color: _vm.buttonColor },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("confirm")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }