<template>
  <div class="d-flex">
    <!-- Text -->
    <p v-if="uploadStatus === 'unfinished'" class="text-left mb-0 mr-1">Reset before uploading a csv file for {{ entity }}.</p>
    <p v-else class="text-left mb-0 mr-1">A csv file for {{ entity }} has been successfully uploaded.</p>

    <v-spacer></v-spacer>

    <!-- Button -->
    <v-btn v-if="uploadStatus === 'done'" x-small :loading="loading" @click="deleteCsv">
      <v-icon x-small class="mr-1">mdi-delete</v-icon>
      Delete file
    </v-btn>
    <v-btn 
      v-else 
      x-small
      class="white--text" 
      color="#00aadb"
      :loading="loading"
      @click="deleteCsv"
    >
      Reset
    </v-btn>
  </div>
</template>

<script>
import { deleteCsv } from "../../../service/csvIntegrationApi";
import { getCsvInfo } from "../../../database/model/model.migration_csv_info"

export default {
  name: "CsvDelete",
  props: { uploadStatus: String, entity: String, filesRequired: Array },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    migrationId() {
      return this.$store.state.firestore.user.last_migration.migration;
    }
  },
  methods: {
    // Delete CSV file from storage and csv_info document from firebase
    async deleteCsv() {
      this.loading = true;
      try {
        for (const file of this.filesRequired) {
          const csvInfo = await getCsvInfo({
            user_id: this.$store.state.firestore.id,
            migration_id: this.migrationId,
            csv_info_id: file,
          })
          if (csvInfo.data()) await deleteCsv(file, this.migrationId);
        }

        this.$emit("file-deleted");
        this.$store.dispatch("setSnackbar", { type: "success", text: "Csv file deleted." });
      } catch (error) {
        console.error(error)
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style>

</style>