<template>
    <v-card :disabled="disabled">
        <v-card-title
            v-if="currentIntegration"
            class="info lighten-1 text-body-1 white--text py-1 mb-5"
        >
            Current connected account:
            <strong class="ml-1">{{ currentIntegration.account_name }}</strong>
        </v-card-title>
        <v-card-text>
            <v-alert
                v-if="!isDestinationSelected && type === 'source'"
                dense
                text
                type="error"
                >You must connect the destination integration first.</v-alert
            >
            <v-select
                v-model="selected"
                :items="list"
                outlined
                :label="labelText"
                :disabled="loading.connect"
            ></v-select>

            <v-text-field
                v-if="selected === 'shopify' && !isConnected"
                v-model="extrasUrlInfo"
                dense
                label="Shop Name"
            ></v-text-field>

            <v-btn
                v-if="!isConnected"
                dark
                color="#00aadb"
                @click="onClickConnect"
                :loading="loading.connect"
                >Connect</v-btn
            >
            <v-btn
                v-if="loading.connect"
                class="ml-3"
                x-small
                text
                @click="undoLoading"
                >Retry</v-btn
            >
            <div v-if="isConnected">
                <v-btn disabled
                    ><v-icon class="mr-3">mdi-check</v-icon>Connected</v-btn
                >
                <v-btn
                    v-if="canReset"
                    class="ml-3"
                    x-small
                    text
                    :loading="loading.reset"
                    @click="resetIntegration"
                >
                    Reset
                </v-btn>
            </div>
            <CsvLocations v-if="selected === 'csv' && isConnected" />
            <TaxSettings
                v-if="isConnected && selected === 'csv'"
                :integration-name="selected"
            />
            <QuickbooksSetup v-if="isConnected && selected === 'quickbooks'" />
        </v-card-text>
    </v-card>
</template>

<script>
import {
    listenIntegration,
    getAllIntegration,
} from "../../database/model/model.integration";
import { updateUser } from "../../database/model/model.user";
import { getInstance } from "../../auth";
import { DoosyncMainApi } from "../../service/main_api";
import { actions } from "../../constants";
import hasPermission from "../../service/permissions";
import CsvLocations from "./CsvLocations.vue";
import TaxSettings from "./TaxSettings.vue";
import QuickbooksSetup from "./QuickbooksSetup.vue";

export default {
    name: "IntegrationSelection",
    components: { CsvLocations, TaxSettings, QuickbooksSetup },
    props: {
        type: String,
        list: Array,
        disabled: Boolean,
        isDestinationSelected: Boolean,
    },
    data() {
        return {
            labelText: `Select the software you're migration data ${
                this.type === "source" ? "FROM" : "TO"
            }`,
            extrasUrlInfo: null,
            selected: null,
            loading: {
                connect: false,
                reset: false,
            },
            listener: null,
        };
    },
    computed: {
        // Return list of all integrations
        integrations() {
            return this.$store.state.firestore.integrations;
        },
        // Return if user is connected to the selected integration
        isConnected() {
            const connected = this.integrations.find(
                (int) => int.name === this.selected
            );
            return connected ? true : false;
        },
        // Return the integration of the current migration
        currentIntegration() {
            return this.integrations.find(
                (integration) =>
                    integration.id ===
                    this.$store.state.firestore.user.last_migration[this.type]
            );
        },
        // Return the oauth urls
        URLs() {
            return this.$store.state.oauth_urls;
        },
        // Return if the user has permission to reset integrations
        canReset() {
            return hasPermission(this.$auth.user, actions.RESET_INTEGRATION);
        },
    },
    methods: {
        // Remove loading and listener
        undoLoading() {
            this.loading.connect = false;
            if (this.listener !== null) this.listener();
        },
        // Choose between opening oauth tab or creating csv integration
        onClickConnect() {
            if (this.selected === "csv" || this.selected === "quickbooks") {
                this.createIntegration(this.selected);
            } else if (this.selected === "shopify") {
                this.getAndListenToUrl().catch(() => {
                    this.$store.dispatch("setSnackbar", {
                        type: "error",
                        text: "Something went wrong. Please try again.",
                    });
                });
            } else {
                this.openOAuthAndListen();
            }
        },
        async getAndListenToUrl() {
            try {
                const doosyncApi = new DoosyncMainApi(getInstance());
                const getUrlResponse = await doosyncApi.getOAuthUrl(
                    this.selected,
                    this.extrasUrlInfo
                );
                this.loading.connect = true;
                try {
                    window.open(getUrlResponse.data, "_blank");
                    this.listener = listenIntegration(
                        this.$store.state.firestore.id
                    );
                } catch (error) {
                    console.log("error ->", error);
                    this.$store.dispatch("setSnackbar", {
                        type: "error",
                        text: "Something went wrong. Please try again.",
                    });
                    this.undoLoading();
                }
            } catch (error) {
                return this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please reload the page.",
                });
            }
        },
        // Open tab for oauth and add listener for new integration
        openOAuthAndListen() {
            if (!this.URLs[this.selected])
                return this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please reload the page.",
                });

            this.loading.connect = true;
            try {
                window.open(this.URLs[this.selected], "_blank");
                this.listener = listenIntegration(
                    this.$store.state.firestore.id
                );
            } catch (error) {
                console.log("error ->", error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please try again.",
                });
                this.undoLoading();
            }
        },
        // Create csv integration in firebase and update state
        async createIntegration(integrationType) {
            this.loading.connect = true;
            try {
                const api = new DoosyncMainApi(getInstance());
                await api.createIntegration(integrationType);

                const allIntegrations = await getAllIntegration(
                    this.$store.state.firestore.id
                );
                this.$store.dispatch(
                    "setFirestoreIntegrations",
                    allIntegrations
                );

                this.$store.dispatch("setSnackbar", {
                    type: "success",
                    text: "Integration added.",
                });
            } catch (error) {
                console.log("error ->", error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please try again.",
                });
            } finally {
                this.loading.connect = false;
            }
        },
        async updateUserWithNewIntegration(integration) {
            const capitalizedType =
                this.type.charAt(0).toUpperCase() + this.type.slice(1);
            try {
                if (
                    !this.currentIntegration ||
                    this.currentIntegration.id !== integration["id"]
                ) {
                    this.$store.dispatch(
                        `setMigration${capitalizedType}`,
                        integration
                    );
                    await updateUser(
                        { [`last_migration.${this.type}`]: integration["id"] },
                        this.$store.state.firestore.id
                    );
                    this.undoLoading();
                    this.$store.dispatch("setSnackbar", {
                        type: "success",
                        text: "Integration added.",
                    });
                }
            } catch (error) {
                console.log("error updating user ->", error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please try again.",
                });
            }
        },
        /**
         * Delete integration and integration_token in firebase and update user.last_migration.source/destination to null
         * @param {String} type - "source" or "destination"
         */
        async resetIntegration() {
            this.loading.reset = true;
            try {
                const integration = this.integrations.find(
                    (int) => int.name === this.selected
                );

                const api = new DoosyncMainApi(getInstance());
                await api.deleteIntegration(this.type, integration.id);

                const newIntegrations = this.integrations.filter(
                    (int) => int.name !== this.selected
                );
                this.$store.dispatch(
                    "setFirestoreIntegrations",
                    newIntegrations
                );

                const capitalizedType =
                    this.type.charAt(0).toUpperCase() + this.type.slice(1);
                this.$store.dispatch(`setMigration${capitalizedType}`, null);

                this.$store.dispatch("setSnackbar", {
                    type: "success",
                    text: "Integration removed.",
                });
            } catch (error) {
                console.log("resetIntegration, error ->", error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please try again.",
                });
            } finally {
                this.loading.reset = false;
            }
        },
        setIntegration() {
            const integrationId = this.currentIntegration.id;
            const integration = this.$store.state.firestore.integrations.find(
                (integration) => integration.id === integrationId
            );
            this.selected = integration.name;
        },
    },
    watch: {
        integrations(newIntegrations) {
            for (const integration of newIntegrations) {
                if (integration.name === this.selected) {
                    this.updateUserWithNewIntegration(integration);
                }
            }
        },
    },
    created() {
        const currentMigration =
            this.$store.state.firestore.user.last_migration;
        if (currentMigration[this.type]) this.setIntegration();
    },
};
</script>

<style lang="scss" scoped>
.v-card {
    text-align: center;
    width: 750px;
}
</style>
