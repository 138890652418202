<template>
    <!-- App loaded -->
    <v-app v-if="!$auth.loading && !loading">
        <Navbar :sidebar="sidebar" @show-sidebar="sidebar = !sidebar" />
        <Sidebar :show="sidebar" @hide-sidebar="sidebar = false" />

        <v-main>
            <router-view />
        </v-main>

        <Snackbar />
    </v-app>

    <!-- Loading spinner -->
    <v-app v-else>
        <div class="app-loading">
            <v-progress-circular
                indeterminate
                color="primary"
                size="80"
                width="7"
            ></v-progress-circular>
        </div>
    </v-app>
</template>

<script>
import { getInstance } from "./auth";
import Sidebar from "./components/ui/Sidebar.vue";
import Navbar from "./components/ui/Navbar.vue";
import Snackbar from "./components/ui/Snackbar.vue";
import { DoosyncMainApi } from "./service/main_api";
import { createIfNewUser } from "./database/model/model.user";
import { getIdFromSub } from "./database/common";
import { getAllIntegration } from "./database/model/model.integration";
import { getMigration } from "./database/model/model.migration";

export default {
    name: "App",
    components: { Navbar, Sidebar, Snackbar },
    data() {
        return {
            sidebar: true,
            loading: true,
        };
    },
    methods: {
        async fireBaseSetup(user) {
            // Get user ID
            let f_uid = getIdFromSub(user.sub);
            this.$store.dispatch("setUserID", f_uid);

            // Fetch user info
            const my_user = await createIfNewUser(
                {
                    email: user.email,
                    register_email: user.email,
                },
                f_uid
            );

            this.$store.dispatch("setFirestoreUser", my_user.data());

            // Fetch integrations
            const all_integration = await getAllIntegration(f_uid);

            this.$store.dispatch("setFirestoreIntegrations", all_integration);

            // Fetch migration if exists
            const migration_id = my_user.data().last_migration?.migration;
            if (migration_id) {
                const migration = await getMigration(f_uid, migration_id);
                this.$store.dispatch("setFirestoreMigration", migration.data());
            } else {
                this.$store.dispatch("setFirestoreMigration", null);
            }
        },
        async getOAuthURLs() {
            try {
                const doosync_main_api = new DoosyncMainApi(getInstance());

                const getVendHQUrl = doosync_main_api.getOAuthUrl("vend");
                const getLighstpeeRetailUrl =
                    doosync_main_api.getOAuthUrl("lightspeed_retail");
                // const getRestoLUrl = doosync_main_api.getOAuthUrl("resto_l");
                // const getRestoKUrl = doosync_main_api.getOAuthUrl("resto_k");

                const [vend, lsRetail] = await Promise.all([
                    getVendHQUrl,
                    getLighstpeeRetailUrl,
                ]);

                return {
                    vend: vend.data,
                    lightspeed_retail: lsRetail.data,
                    // resto_l: restoL.data,
                    // resto_k: restoK.data
                };
            } catch (error) {
                console.log("error ->", error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please reload the page.",
                });
            }
        },
    },
    created() {
        const instance = getInstance();

        instance.$watch("loading", async (loading) => {
            if (!loading && instance.isAuthenticated) {
                const doosync_main_api = new DoosyncMainApi(instance);
                // todo how to handle the error

                await doosync_main_api.getFirebaseCredential();

                this.fireBaseSetup(instance.user);
                const urls = await this.getOAuthURLs();
                this.$store.dispatch("setOAuthURLs", urls);
                this.$store.dispatch("setSnackbar", {
                    type: "success",
                    text: "Page loaded.",
                });
                this.loading = false;
            }
        });
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap");

.v-main__wrap {
    background-color: #fafafa;
}

html {
    overflow-y: auto;
}

* {
    font-family: Nunito, sans-serif;
}

.app-loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
