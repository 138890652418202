var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$auth.loading && !_vm.loading
    ? _c(
        "v-app",
        [
          _c("Navbar", {
            attrs: { sidebar: _vm.sidebar },
            on: {
              "show-sidebar": function ($event) {
                _vm.sidebar = !_vm.sidebar
              },
            },
          }),
          _c("Sidebar", {
            attrs: { show: _vm.sidebar },
            on: {
              "hide-sidebar": function ($event) {
                _vm.sidebar = false
              },
            },
          }),
          _c("v-main", [_c("router-view")], 1),
          _c("Snackbar"),
        ],
        1
      )
    : _c("v-app", [
        _c(
          "div",
          { staticClass: "app-loading" },
          [
            _c("v-progress-circular", {
              attrs: {
                indeterminate: "",
                color: "primary",
                size: "80",
                width: "7",
              },
            }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }