<template>
  <v-card class="d-flex flex-column pa-5">
    <!-- Title -->
    <v-card-title class="justify-center">
      1. Configure your migration
    </v-card-title>
    
    <v-card-text class="pb-0">
      <!-- Locations mapping -->
      <LocationsMapping 
        ref="locationsMapping" 
        :step="step" 
        :integrations-snapshots="integrationsSnapshots" 
        @saved="onSavedNextStep" 
      />

      <v-divider v-if="hasEntitiesSelectionPermission"></v-divider>

      <!-- Entities selections -->
      <EntitiesSelection 
        ref="entitiesSelection" 
        :has-permission="hasEntitiesSelectionPermission"
        :step="step" 
        :integrations-snapshots="integrationsSnapshots" 
        @saved="onSavedNextStep" 
      />

      <!-- Change configuration button -->
      <div v-if="step > 1" class="d-flex justify-end">
        <v-btn 
          x-small 
          :disabled="disableChangeButton" 
          @click="$emit('update:step', 1)"
        >
          Change configuration
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { createMigration } from "../../database/model/model.migration";
import { createIfNewUser } from "../../database/model/model.user";
import { actions } from "../../constants"
import hasPermission from "../../service/permissions"
import LocationsMapping from "./configuration/LocationsMapping.vue";
import EntitiesSelection from "./configuration/EntitiesSelection.vue";

export default {
  name: "Configuration",
  components: { EntitiesSelection, LocationsMapping },
  props: {
    step: Number,
    status: String,
  },
  computed: {
    // Return if the user has the multi-locations-mapping permission
    hasEntitiesSelectionPermission() {
      return hasPermission(this.$auth.user, actions.SELECT_ENTITIES)
    },
    // Build the integrations snapshots object for the migration collection
    integrationsSnapshots() {
      const integrations = this.$store.state.firestore.integrations.map(int => {
        const { id, ...integration } = int;
        return {
          id,
          integration
        }
      })

      let source_snapshot = null
      let destination_snapshot = null

      // Extract snapshot info from currently selected destination and source integration
      for (const integration of integrations) {
        if (integration['id'] === this.$store.state.firestore.user.last_migration.source ) {
          source_snapshot = integration
        }
        if (integration['id'] === this.$store.state.firestore.user.last_migration.destination ) {
          destination_snapshot = integration
        }
      }

      return {
        source_snapshot, destination_snapshot
      }
    },
    // Return the migration id of the current migration
    migrationId() {
      return this.$store.state.firestore.user.last_migration.migration;
    },
    // Return if the Change config button should be disabled or not
    disableChangeButton() {
      const disabledStatus = ["RUNNING", "QUEUED", "PAUSED", "DONE"];
      return disabledStatus.includes(this.status)
    }
  },
  methods: {
    // Reset entities and locations mapping
    reset() {
      this.$refs.locationsMapping.reset();
      this.$refs.entitiesSelection.reset();
    },
    // When migration is updated, check if allowed to move on to next step
    onSavedNextStep() {
      const currentMigration = this.$store.state.firestore.migration;
      if (this.hasEntitiesSelectionPermission) {
        if (currentMigration.location_mapping.length > 0 && Object.keys(currentMigration.entities).length > 0) {
          this.$emit("update:step", 2)
        }
      } else {
        this.$emit("update:step", 2)
      }
    },
    async createMigrationSetup() {
      try {
        // Create migration
        const { source_snapshot, destination_snapshot } = this.integrationsSnapshots;

        await createMigration(this.$store.state.firestore.id, { source_snapshot, destination_snapshot }, this.hasEntitiesSelectionPermission);

        // Refresh user info
        const user = await createIfNewUser({}, this.$store.state.firestore.id);
        
        this.$store.dispatch("setFirestoreUser", user.data())

        // Add listener and move to next step
        const migration_id = user.data().last_migration?.migration;
        if (migration_id) {
          this.$emit("add-listener");
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please refresh the page." });
        console.log("error ->", error);
      }
    },
  },
  watch: {
    migrationId: {
      immediate: true,
      handler(id) {
        if (!id) this.createMigrationSetup()
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .v-card {
    text-align: center;
    width: 750px;

    .date-timezone {
      max-width: 150px;
    }

    .subtext {
      font-size: 12px;
    }

    .v-input--selection-controls {
      padding-top: 0;
    }
  }
</style>