<template>
  <v-alert
    :value="show"
    dense
    text
    type="error"
    width="750"
    max-width="100%"
  >
    <v-row align="center">
      <v-col class="grow">
        You cannot modify your integrations while a migration is ongoing.
      </v-col>
      <v-col class="shrink">
        <v-btn x-small to="/migration">Back to migration page</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "DisabledAlert",
  props: { show: Boolean }
}
</script>

<style>

</style>