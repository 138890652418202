<template>
  <v-card class="mt-5 d-flex flex-column">
    <v-card-title class="justify-center">
      3. Follow the migration progress
    </v-card-title>

    <div v-if="step === 2 && (status === 'RUNNING' || status === 'DONE' || status === 'QUEUED' || status === 'PAUSED')">
      <v-card-text>
        <!-- Complete / Time left -->
        <div class="mb-5">
          <h4 v-if="status === 'DONE'">Migration complete</h4>
          <div v-else>
            <h4>Time before completion:</h4>
            <h2>{{ migrationProgress.timeLeft }} seconds</h2>
          </div>
        </div>

        <!-- Progress bar -->
        <v-progress-linear
          :value="migrationProgress.percentage"
          height="25"
          rounded
          color="#00aadb"
          class="mb-5"
        >
          <strong>{{ migrationProgress.percentage }}%</strong>
        </v-progress-linear>

        <!-- Entities' progress -->
        <v-data-table :headers="headers" :items="entitiesProgress" hide-default-footer sort-by="name">
          <template v-slot:item.fetch="{ item }">
            <div class="d-flex flex-column justify-center align-center my-1">
              <v-icon v-if="item.fetch.status === 'NOT_STARTED'">mdi-minus</v-icon>
              <v-icon v-if="item.fetch.status === 'DONE'" color="success">mdi-check</v-icon>
              <v-icon v-else-if="item.fetch.status === 'RUNNING' && status === 'PAUSED'" color="warning">mdi-pause</v-icon>
              <v-progress-circular v-else-if="item.fetch.status === 'RUNNING'" indeterminate color="primary" size="20" width="3" class="mb-1"></v-progress-circular>
              <span v-if="item.fetch.status === 'DONE' || item.fetch.status === 'RUNNING'">{{ item.fetch.count_done }} / {{ item.fetch.count_total }}</span>
              <span v-if="item.fetch.status === 'DONE' || item.fetch.status === 'RUNNING'">{{ item.fetch.count_error }} error(s)</span>
            </div>
          </template>
          <template v-slot:item.convert="{ item }">
            <div class="d-flex flex-column justify-center align-center my-1">
              <v-icon v-if="item.convert.status === 'NOT_STARTED'">mdi-minus</v-icon>
              <v-icon v-if="item.convert.status === 'DONE'" color="success">mdi-check</v-icon>
              <v-icon v-else-if="item.convert.status === 'RUNNING' && status === 'PAUSED'" color="warning">mdi-pause</v-icon>
              <v-progress-circular v-else-if="item.convert.status === 'RUNNING'" indeterminate color="primary" size="20" width="3" class="mb-1"></v-progress-circular>
              <span v-if="item.convert.status === 'DONE' || item.convert.status === 'RUNNING'">{{ item.convert.count_done }} / {{ item.convert.count_total }}</span>
              <span v-if="item.convert.status === 'DONE' || item.convert.status === 'RUNNING'">{{ item.convert.count_error }} error(s)</span>
            </div>
          </template>
          <template v-slot:item.push="{ item }">
            <div class="d-flex flex-column justify-center align-center my-1">
              <v-icon v-if="item.push.status === 'NOT_STARTED'">mdi-minus</v-icon>
              <v-icon v-if="item.push.status === 'DONE'" color="success">mdi-check</v-icon>
              <v-icon v-else-if="item.push.status === 'RUNNING' && status === 'PAUSED'" color="warning">mdi-pause</v-icon>
              <v-progress-circular v-else-if="item.push.status === 'RUNNING'" indeterminate color="primary" size="20" width="3" class="mb-1"></v-progress-circular>
              <span v-if="item.push.status === 'DONE' || item.push.status === 'RUNNING'">{{ item.push.count_done }} / {{ item.push.count_total }}</span>
              <span v-if="item.push.status === 'DONE' || item.push.status === 'RUNNING'">{{ item.push.count_error }} error(s)</span>
            </div>
          </template>
          <template v-slot:item.download="{ item }">
            <v-btn 
              icon 
              :disabled="isEntityDisabled(item)"
              :loading="loading[`${item.name}_download_error`]"
              @click="downloadErrorFile(item.name)"><v-icon>mdi-download</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
    <div v-if="status === 'DONE'">
        <v-btn class="white--text" color="#00aadb" :disabled="migration_errors.disabled" :loading="migration_errors.loading" @click="fetchErrors">Get record</v-btn>
        <v-data-table
          :headers="migration_errors.headers"
          :items="migration_errors.values"
          :items-per-page="20"
          class="elevation-1"
        ></v-data-table>
    </div>
  </v-card>
</template>

<script>
import { getMigrationError } from "../../database/model/model.migration_error";
import { ref, getDownloadURL } from "firebase/storage";
import { firebaseStorage } from "../../service/firebaseConfig";

export default {
  name: "Progress",
  props: { step: Number, status: String },
  data() {
    return {
      migration_errors: {
        disabled: false,
        headers:[],
        values:[]
      },
      headers: [
        {
          text: "Entity",
          value: "name",
        },
        {
          text: "Fetching",
          value: "fetch",
          align: "center"
        },
        {
          text: "Converting",
          value: "convert",
          align: "center"
        },
        {
          text: "Inserting",
          value: "push",
          align: "center"
        },
      ],
      loading: {
        migration_errors: false,
      }
    }
  },
  methods:{
    async fetchErrors() {
      this.loading.migration_errors = true  
      try {
        const errors = await getMigrationError(this.userId, this.migrationId);
        console.log("errors ->", errors);

        if (errors.length > 0) {
          this.migration_errors.headers.push(
              ...Object.keys(errors[0]).map(
              key => {
                  return {
                    text: key,
                    value: key,
                  }
              }
            )
          )
        }
        this.migration_errors.values.push(...errors);
        this.migration_errors.disabled = true
      } catch (error) {
        console.error(error);
      } finally {
        this.loading.migration_errors = false
      }
    },
    // Get the download URL of the error file and downloads it
    async downloadErrorFile(entityName) {
      this.loading[`${entityName}_download_error`] = true;
      try { 
        const path = `user/${this.userId}/error_reports/${this.migrationId}/${entityName}.csv`;
        const url = await getDownloadURL(ref(firebaseStorage, path));

        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none')
        a.href = url
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()
      } catch (error) { 
        console.error(error);
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
      } finally {
        this.loading[`${entityName}_download_error`] = false;
      }
    },
    isEntityDisabled(item) {
      const steps = ["fetch", "convert", "push"];

      let isDisabled = true;
      steps.forEach(step => {
        if (item[step].count_error && item[step].count_error > 0) {
          isDisabled = false;
        }
      });
      return isDisabled;
    }
  },
  computed: {
    // Return the user id
    userId() {
      return this.$store.state.firestore.id;
    },
    // Return the id of the current migration
    migrationId() {
      return this.$store.state.firestore.user.last_migration.migration;
    },
    // Build the migration progress data used by the progress bar
    migrationProgress() {
      const prog = this.$store.state.firestore.migration?.progress;
      if (!prog) return null
      const total = prog.total;
      const migrated = prog.count_converted + prog.count_fetch + prog.count_push;
      const remaining = total - migrated;

      if (this.status === "DONE") {
        return {
          percentage: 100
        }
      } else if (this.status === "RUNNING" && total === 0 || this.status === "QUEUED" && total === 0) {
        return {
          percentage: 0,
          timeLeft: Math.round(remaining * prog.average_time)
        }
      } else {
        return {
          percentage: Math.round((migrated / total) * 1000) / 10 || 0,
          timeLeft: Math.round(remaining * prog.average_time)
        }
      }
    },
    // Build the progress data used in the progress' details
    entitiesProgress() {
      const output = [];
      if (!this.$store.state.firestore.migration) return output
      for (const entity in this.$store.state.firestore.migration.entities) {
        if (!this.loading[`${entity}download_error`]) this.$set(this.loading, `${entity}_download_error`, false);

        const entityObject = this.$store.state.firestore.migration.entities[entity];
        const states = ["fetch", "convert", "push"];
        const entitySteps = {
          fetch: {},
          convert: {},
          push: {}
        };
        
        states.forEach(state => {
          if (state === "push" && !entityObject.to_migrate) {
            entitySteps.push.status = null;
          } else {
            if (entityObject.state[state].is_done) entitySteps[state].status = "DONE";
            else if (entityObject.state[state].count_total === 0) entitySteps[state].status = "NOT_STARTED";
            else entitySteps[state].status = "RUNNING";

            entitySteps[state].count_done = entityObject.state[state].count_done
            entitySteps[state].count_total = entityObject.state[state].count_total
            entitySteps[state].count_error = entityObject.state[state].count_error
          }
        });

        output.push({
          name: entityObject.entity_name,
          fetch: entitySteps.fetch,
          convert: entitySteps.convert,
          push: entitySteps.push
        })
      }
      return output;
    },
  },
  watch: {
    status: {
      immediate: true,
      handler(value) {
        if (value === "DONE") {
          this.headers.push({
            text: "Download errors",
            value: "download",
            align: "center"
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .v-card {
    text-align: center;
    width: 750px;
  }
</style>