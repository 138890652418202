var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-center" },
    [
      _c("v-icon", { attrs: { color: "error", size: "150" } }, [
        _vm._v("mdi-close-circle-outline"),
      ]),
      _c("p", [_vm._v("The csv file could not be validated.")]),
      _c(
        "div",
        { staticClass: "align-self-end pa-1" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                loading: _vm.loading.download,
                disabled: _vm.path === "",
              },
              on: { click: _vm.downloadError },
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                _vm._v("mdi-download"),
              ]),
              _vm._v(" Download errors file "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text ml-2",
              attrs: { color: "#00aadb", loading: _vm.loading.close },
              on: { click: _vm.close },
            },
            [_vm._v(" Close & restart ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }