var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "0" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pb-0" },
        [
          _vm.hasPermission
            ? _c("div", { staticClass: "my-3" }, [
                _vm._v(" Select the "),
                _c("b", [_vm._v("entities")]),
                _vm._v(" to migrate: "),
              ])
            : _c("div", { staticClass: "my-3" }, [
                _c("b", [_vm._v("Entities")]),
                _vm._v(" that will be migrated: "),
              ]),
          _c(
            "v-alert",
            {
              attrs: {
                value: Object.keys(_vm.selectedEntities).length === 0,
                dense: "",
                text: "",
                type: "error",
                transition: "slide-x-transition",
              },
            },
            [_vm._v("You must select at least one entity to migrate.")]
          ),
          _c(
            "div",
            _vm._l(_vm.entitiesNames, function (entity) {
              return _c("div", { key: entity, staticClass: "mb-4" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between align-center" },
                  [
                    _c("v-checkbox", {
                      staticClass: "mt-0",
                      attrs: {
                        label: _vm.entityToLabel[entity],
                        "hide-details": "",
                        disabled:
                          _vm.step !== 1 ||
                          !_vm.hasPermission ||
                          (entity === "sales" &&
                            !_vm.entities.items.to_migrate),
                      },
                      model: {
                        value: _vm.entities[entity].to_migrate,
                        callback: function ($$v) {
                          _vm.$set(_vm.entities[entity], "to_migrate", $$v)
                        },
                        expression: "entities[entity].to_migrate",
                      },
                    }),
                    _vm.isCsvIntegration &&
                    _vm.entities[entity].to_migrate &&
                    _vm.step === 1
                      ? _c("CsvDialog", {
                          attrs: {
                            entity: entity,
                            entitySelected: _vm.entities[entity].to_migrate,
                            "files-required": _vm.entitiesFiles[entity],
                            uploadDone:
                              _vm.entitiesWithCsvUploaded.includes(entity),
                            uploadNotFinished:
                              _vm.entitiesWithCsvUnfinished.includes(entity),
                          },
                          on: {
                            "file-uploaded": function ($event) {
                              return _vm.entitiesWithCsvUploaded.push(entity)
                            },
                            "file-deleted": _vm.updateEntitiesWithCsv,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _vm.hasPermission
        ? _c(
            "v-card-actions",
            { staticClass: "pt-2" },
            [
              _c("v-spacer"),
              _vm.step === 1
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "#00aadb",
                            small: "",
                            loading: _vm.loading,
                          },
                          on: { click: _vm.saveEntities },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-content-save")]
                          ),
                          _vm._v(" Save selection "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }