<template>
<div class="d-flex justify-center">
    <v-card class="mt-5 mx-2 flex-shrink-0" min-width="700" max-width="800">
      <!-- Title -->
      <v-card-title class="d-flex justify-center">Not allowed</v-card-title>

      <!-- Icon and text -->
      <v-card-text>
        <div class="d-flex align-center justify-center"> 
          <v-icon color="error" size="80">mdi-alert-circle-outline</v-icon>
          <div class="ml-2">
            <p>{{ text }}.</p>
            <p>You cannot access this page.</p>
          </div>
        </div>
      </v-card-text>

      <!-- Back to setup button -->
      <v-card-actions class="d-flex justify-center">
        <v-btn color="secondary" :to="`/${path}`">Back to {{ path }}</v-btn>
      </v-card-actions>
    </v-card>
</div>
</template>

<script>
export default {
  name: "NotAllowed",
  props: {
    text: String,
    path: String
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>