var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-1" },
    [
      _c("v-file-input", {
        attrs: {
          label: "Upload your CSV file for " + _vm.entity,
          accept: ".csv",
        },
        model: {
          value: _vm.file,
          callback: function ($$v) {
            _vm.file = $$v
          },
          expression: "file",
        },
      }),
      _vm.preview.headers.length > 0
        ? _c("div", { staticClass: "preview" }, [
            _c("table", [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.preview.headers, function (header, index) {
                    return _c(
                      "th",
                      { key: "header" + index, staticClass: "greyed-cell" },
                      [_vm._v(" " + _vm._s(header) + " ")]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.preview.items.slice(0, 10), function (item, index) {
                  return _c(
                    "tr",
                    { key: index },
                    _vm._l(item, function (column, index) {
                      return _c("td", { key: index }, [_vm._v(_vm._s(column))])
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "error", loading: _vm.loading.cancel },
              on: {
                click: function ($event) {
                  _vm.showConfirm = true
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              on: {
                click: function ($event) {
                  return _vm.$emit("close-dialog")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: {
                color: "#00aadb",
                disabled: !_vm.file,
                loading: _vm.loading.confirm,
              },
              on: {
                click: function ($event) {
                  return _vm.uploadCsv(_vm.entity)
                },
              },
            },
            [_vm._v(" Confirm ")]
          ),
          _c("ActionConfirmation", {
            attrs: {
              text: "Are you sure? Your upload progress will be lost.",
              "button-text": "cancel upload",
              open: _vm.showConfirm,
            },
            on: {
              back: function ($event) {
                _vm.showConfirm = false
              },
              confirm: _vm.cancel,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }