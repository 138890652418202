<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :options="{ itemsPerPage: 10 }"
        loading-text="Loading..."
        item-key="id"
        :loading="loading"
        show-expand
        single-expand
        @item-expanded="getMigrationById"
        class="elevation-1"
    >
        <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length" class="expanded-table">
                <MigrationsDetails
                    :headers="expanded.headers"
                    :items="expanded.items"
                    :loading="expanded.loading"
                />
            </td>
        </template>
    </v-data-table>
</template>

<script>
import {
    getAllMigrations,
    getMigration,
} from "../database/model/model.migration";
import MigrationsDetails from "../components/history/MigrationsDetails.vue";

export default {
    name: "MigrationsHistory",
    components: { MigrationsDetails },
    data() {
        return {
            // TODO: add date column (may require to add timestamp when calling createMigration)
            headers: [
                {
                    text: "Migration ID",
                    value: "id",
                    align: "start",
                    sortable: true,
                },
                {
                    text: "Source",
                    value: "source",
                    sortable: true,
                },
                {
                    text: "Destination",
                    value: "destination",
                    sortable: true,
                },
                {
                    text: "State",
                    value: "state",
                    sortable: true,
                },
            ],
            items: [],
            integrationsNames: {
                vend: "VendHQ",
                lightspeed_retail: "Lightspeed Retail",
                csv: "CSV files",
            },
            loading: false,
            expanded: {
                loading: false,
                items: [],
            },
        };
    },
    methods: {
        // Get all migrations of the user and format the data for the migrations history table
        async getMigrations() {
            this.loading = true;
            try {
                const migrations = await getAllMigrations(
                    this.$store.state.firestore.id
                );
                for (const mig of migrations) {
                    if (!mig.source_snapshot || !mig.destination_snapshot) {
                        continue;
                    }

                    this.items.push({
                        id: mig.id,
                        source: this.integrationsNames[
                            mig.source_snapshot.integration.name
                        ],
                        destination:
                            this.integrationsNames[
                                mig.destination_snapshot.integration.name
                            ],
                        total: mig.progress.total,
                        state: mig.state.control_step,
                        date: null,
                    });
                }
                this.$store.dispatch("setSnackbar", {
                    type: "success",
                    text: `Migrations history loaded.`,
                });
            } catch (error) {
                console.log("getMigrations, error ->", error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: `Something went wrong. Please reload the page.`,
                });
            } finally {
                this.loading = false;
            }
        },
        // Fetch the migration info and format data for the details table
        async getMigrationById({ item }) {
            this.expanded.loading = true;
            this.expanded.items = [];
            try {
                const migration = await getMigration(
                    this.$store.state.firestore.id,
                    item.id
                );
                const { entities } = migration.data();
                for (const entityName in entities) {
                    const {
                        entity_name,
                        state: { fetch, convert, push },
                    } = entities[entityName];
                    this.expanded.items.push({
                        entity_name,
                        fetch,
                        convert,
                        push,
                    });
                }
            } catch (error) {
                console.log("getMigrationById, error ->", error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: `Something went wrong. Please try again.`,
                });
            } finally {
                this.expanded.loading = false;
            }
        },
    },
    created() {
        this.getMigrations();
    },
};
</script>

<style scoped lang="scss">
.expanded-table {
    padding: 0 !important;
}
</style>
