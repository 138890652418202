var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "extended-table",
    attrs: {
      headers: _vm.headers,
      items: _vm.fetchitems,
      loading: _vm.loading,
      "loading-text": "Loading...",
      "hide-default-footer": "",
    },
    scopedSlots: _vm._u([
      {
        key: "item.fetch",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              " " +
                _vm._s(item.fetch.count_done) +
                " / " +
                _vm._s(item.fetch.count_total) +
                " "
            ),
            _c("span", { staticClass: "errors" }, [
              _vm._v("(" + _vm._s(item.fetch.count_error) + " error(s))"),
            ]),
          ]
        },
      },
      {
        key: "item.convert",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              " " +
                _vm._s(item.convert.count_done) +
                " / " +
                _vm._s(item.convert.count_total) +
                " "
            ),
            _c("span", { staticClass: "errors" }, [
              _vm._v("(" + _vm._s(item.convert.count_error) + " error(s))"),
            ]),
          ]
        },
      },
      {
        key: "item.push",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              " " +
                _vm._s(item.push.count_done) +
                " / " +
                _vm._s(item.push.count_total) +
                " "
            ),
            _c("span", { staticClass: "errors" }, [
              _vm._v("(" + _vm._s(item.push.count_error) + " error(s))"),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }