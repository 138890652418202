var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-center" },
    [
      _c("v-icon", { attrs: { color: "success", size: "150" } }, [
        _vm._v("mdi-check-circle-outline"),
      ]),
      _c("p", [_vm._v("The csv file is validated.")]),
      _c(
        "div",
        { staticClass: "align-self-end pa-1" },
        [
          _c(
            "v-btn",
            {
              attrs: { loading: _vm.loading.cancel },
              on: {
                click: function ($event) {
                  _vm.showConfirm = true
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _vm.isLastFile
            ? _c(
                "v-btn",
                {
                  staticClass: "white--text ml-2",
                  attrs: { color: "#00aadb", loading: _vm.loading.confirm },
                  on: { click: _vm.onClickConfirm },
                },
                [_vm._v(" Confirm ")]
              )
            : _c(
                "v-btn",
                {
                  staticClass: "white--text ml-2",
                  attrs: { color: "#00aadb", loading: _vm.loading.nextFile },
                  on: { click: _vm.onClickConfirmGoNext },
                },
                [_vm._v(" Next ")]
              ),
          _c("ActionConfirmation", {
            attrs: {
              text: "Are you sure? Your upload progress will be lost.",
              "button-text": "cancel upload",
              open: _vm.showConfirm,
            },
            on: {
              back: function ($event) {
                _vm.showConfirm = false
              },
              confirm: _vm.cancel,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }