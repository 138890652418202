export const roles = {
  ADMIN: "Administrator",
  LIGHTSPEED_TO_VEND: "Retail to Vend",
  QUICKBOOKS_TO_VEND: "Quickbooks to Vend",
  USER: "Regular user"
}

export const actions = {
  // Migration control
  PAUSE_MIGRATION: "PAUSE_MIGRATION",
  RESUME_MIGRATION: "RESUME_MIGRATION",
  CANCEL_MIGRATION: "CANCEL_MIGRATION",

  // Migration setup
  MULTI_LOCATIONS_MAPPING: "MULTI_LOCATIONS_MAPPING",
  SELECT_ENTITIES: "SELECT_ENTITIES",
  NO_MIGRATION_LIMIT: "NO_MIGRATION_LIMIT",

  // Integrations access
  LIGHTSPEED_RETAIL_INTEGRATION: "LIGHTSPEED_RETAIL_INTEGRATION",
  QUICKBOOKS_INTEGRATION: "QUICKBOOKS_INTEGRATION",
  VEND_INTEGRATION: "VEND_INTEGRATION",
  CSV_INTEGRATION: "CSV_INTEGRATION",
  SHOPIFY_INTEGRATION: "SHOPIFY_INTEGRATION",

  // Integration control
  RESET_INTEGRATION: "RESET_INTEGRATION",

  // Administration Panel
  ADMIN_PAGE: "ADMIN_PAGE"
}