var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-divider", { staticClass: "mt-4" }),
      _c(
        "v-card",
        { attrs: { elevation: "0" } },
        [
          _c("v-card-title", { staticClass: "subtitle-1" }, [
            _vm._v("Manage your locations for the csv files:"),
          ]),
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                _vm._l(_vm.locations, function (location, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "d-flex" },
                    [
                      _c("v-text-field", {
                        staticClass: "mr-2 shrink",
                        attrs: {
                          label: "Location ID",
                          rules: [
                            _vm.rules.required,
                            _vm.checkDuplicate(index),
                          ],
                          disabled: !_vm.editable,
                          outlined: "",
                          dense: "",
                        },
                        model: {
                          value: location.id,
                          callback: function ($$v) {
                            _vm.$set(location, "id", $$v)
                          },
                          expression: "location.id",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Location name",
                          disabled: !_vm.editable,
                          rules: [_vm.rules.required],
                          outlined: "",
                          dense: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append-outer",
                              fn: function () {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: _vm.locations.length === 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeLocations(index)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: location.name,
                          callback: function ($$v) {
                            _vm.$set(location, "name", $$v)
                          },
                          expression: "location.name",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pt-0" },
            [
              _c("v-spacer"),
              _vm.editable
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-3",
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.changeLocations(null)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-plus")]
                          ),
                          _vm._v("Add a location"),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "#00aadb",
                            small: "",
                            loading: _vm.loading,
                            disabled: !_vm.valid,
                          },
                          on: { click: _vm.save },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("mdi-content-save")]
                          ),
                          _vm._v("Save locations settings"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.editable = true
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-pencil")]
                      ),
                      _vm._v("Edit locations"),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }