<template>
  <v-card elevation="0">
    <v-card-text class="pb-0">
      <!-- Title -->
      <div :class="`mb-${hasLocationsMapping ? '6' : '0'}`">Map <b>locations</b> between your source and destination accounts:</div>

      <!-- No locations mapping alert -->
      <v-alert
        :value="!hasLocationsMapping"
        class="mb-6"
        dense
        text
        type="error"
        transition="slide-x-transition"
      >You must have at least one locations mapping saved.</v-alert>
      
      <!-- Locations mapping -->
      <v-row v-for="(map, index) in mapping" :key="index">

        <!-- Source location -->
        <v-col class="py-0">
          <v-select 
            v-model="map.source" 
            :items="locations.source"  
            :disabled="step !== 1" 
            return-object 
            outlined 
            dense
          ></v-select>
        </v-col>

        <v-col cols="2" class="py-0">
          <v-icon size="30" :disabled="step !== 1">mdi-arrow-right-bold</v-icon>
        </v-col>

        <!-- Destination location -->
        <v-col class="py-0">
          <v-select 
            v-model="map.destination" 
            :items="locations.destination" 
            :disabled="step !== 1" 
            return-object 
            outlined 
            dense
          >

            <!-- Delete mapping button -->
            <template v-slot:append-outer v-if="hasMultiLocationsMappingPermission">
              <v-btn 
                v-if="step === 1" 
                icon 
                :disabled="mapping.length === 1" 
                @click="changeMapping(index)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Footer -->
    <v-card-actions class="pt-2 pb-5">
      <v-spacer></v-spacer>
      <div v-if="step === 1">

        <!-- Add button -->
        <v-btn 
          v-if="hasMultiLocationsMappingPermission"
          class="mr-3" 
          small
          @click="changeMapping(null)" 
        >
          <v-icon class="mr-1" small>mdi-plus</v-icon>
          Add location mapping
        </v-btn>

        <!-- Save button -->
        <v-btn 
          class="white--text" 
          color="#00aadb" 
          small 
          :loading="loading"
          @click="saveMapping"
        >
          <v-icon small class="mr-1">mdi-content-save</v-icon>
          Save locations settings
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { updateMigration } from "../../../database/model/model.migration";
import { actions } from "../../../constants"
import hasPermission from "../../../service/permissions"

export default {
  name: "LocationsMapping",
  props: { step: Number, integrationsSnapshots: Object },
  data() {
    return {
      locations: {
        source: [],
        destination: []
      },
      mapping: [],
      sourceLocation: "",
      destinationLocation: "",
      loading: false,
    }
  },
  computed: {
    // Return if the user has the multi-locations-mapping permission
    hasMultiLocationsMappingPermission() {
      return hasPermission(this.$auth.user, actions.MULTI_LOCATIONS_MAPPING)
    },
    // Build the locations mapping object for the migration collection
    formattedMapping() {
      return this.mapping.map(locationMapping => {
        return {
          source_id: locationMapping.source.value,
          source_name: locationMapping.source.text,
          destination_id: locationMapping.destination.value,
          destination_name: locationMapping.destination.text,
        }
      })
    },
    // Return if the current migration already contains a locations mapping
    hasLocationsMapping(){
      return this.$store.state.firestore.migration?.location_mapping && this.$store.state.firestore.migration?.location_mapping.length > 0
    }
  },
  methods: {
    // Set locations lists and default selected location if no migration
    setLocations() {
      for (const integration of this.$store.state.firestore.integrations) {
              
        if (integration['id'] === this.$store.state.firestore.user.last_migration.source ) {
          for (const location of integration['locations']) { 
            this.locations.source.push({ text:location['name'], value: `${location['id']}` })
          }
        }
        
        if (integration['id'] === this.$store.state.firestore.user.last_migration.destination ) {
          for (const location of integration['locations']) { 
            this.locations.destination.push({ text:location['name'], value: `${location['id']}` })
          }
        }
      }

      if (!this.$store.state.firestore.user.last_migration?.migration || !this.hasLocationsMapping) {
        this.reset();
      }
    },
    // Set initial entities values and selected locations if there's a current migration
    initiateMapping() {
      const currentMigration = this.$store.state.firestore.migration;
      if (this.hasLocationsMapping) {
        this.mapping = currentMigration.location_mapping.map(locationMapping => {
          return {
            source: {
              value: locationMapping.source_id,
              text: locationMapping.source_name,
            },
            destination: {
              value: locationMapping.destination_id,
              text: locationMapping.destination_name,
            },
          }
        })
      }
    },
    // Add or delete mapping in the data
    changeMapping(index) {
      if (Number.isInteger(index)) {
        this.mapping.splice(index, 1);
      } else {
        this.mapping.push({
          source: this.locations.source[0],
          destination: this.locations.destination[0]
        });
      }
    },
    // Reset entities and locations
    reset() {
      this.mapping = [{
        source: this.locations.source[0],
        destination: this.locations.destination[0]
      }]
    },
    async saveMapping() {
      this.loading = true;
      try {
        const { source_snapshot, destination_snapshot } = this.integrationsSnapshots;
        const payload = { location_mapping: this.formattedMapping, source_snapshot, destination_snapshot }
        await updateMigration(this.$store.state.firestore.id, this.$store.state.firestore.user.last_migration.migration, payload);
        this.$emit("saved")
        this.$store.dispatch("setSnackbar", { type: "success", text: `Locations mapping saved.` })
      } catch (error) {
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
        console.log("error ->", error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.setLocations();
    const currentMigration = this.$store.state.firestore.migration;
    if (currentMigration && currentMigration.location_mapping ) {
      this.initiateMapping();
    }
  }
}
</script>

<style>

</style>