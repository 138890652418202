<template>
  <v-navigation-drawer dark app v-model="visible" mobile-breakpoint="834">
    <div class="nav-button-container">
      <div>
        <img
          src="https://dohn7qwk9v0u7.cloudfront.net/branding/2018/png/logo-doosync-rgb.ai-blue.png"
          class="toolbar-branding d-flex pa-3"
        />
        <p class="white--text text-caption font-italic font-weight-thin pl-3">{{ $auth.user.email }}</p>
        <div class="mt-10">
          <v-btn depressed block tile to="/setup">
            <v-icon>mdi-cogs</v-icon>
            Setup
          </v-btn>
          <v-btn depressed block tile to="/migration">
            <v-icon>mdi-play-pause</v-icon>
            Migration
          </v-btn>
          <v-btn depressed block tile to="/history">
            <v-icon>mdi-clipboard-text-clock</v-icon>
            Migrations history
          </v-btn>
        </div>
      </div>
      <div>
        <v-btn v-if="showAdmin()" depressed block tile to="/admin">
          <v-icon>mdi-account</v-icon>
          Admin Page
        </v-btn>
        <v-btn depressed block tile to="/profile">
          <v-icon>mdi-account</v-icon>
          Profile
        </v-btn>
        <v-btn block depressed tile @click="logout">
          <v-icon>mdi-lock</v-icon>
          Logout
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { actions } from "../../constants"
import hasPermission from "../../service/permissions"

export default {
  name: "Sidebar",
  props: { show: Boolean },
  data() {
    return {
      visible: this.show,
      setupComplete: false,
    };
  },
  watch: {
    show() {
      this.visible = this.show
    },
    visible(value) {
      if (!value) this.$emit("hide-sidebar")
    }
  },
  methods: {
    showAdmin(){
      return hasPermission(this.$auth.user, actions.ADMIN_PAGE)
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
};
</script>

<style scoped lang="scss">
.theme--light {
  color: white;

  .v-navigation-drawer {
    background-color: #18191d;
  }

  .nav-button-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      width: 65%;
    }

    .v-btn:not(.v-btn--round).v-size--default {
      padding: 16px;
      justify-content: start;
      height: 70px;
      // color: #555;
      background-color: transparent;
      letter-spacing: normal;
      font-weight: 700;

      .v-icon {
        margin: 0 1rem 0 5px;
        padding-left: 16px;
      }
    }

    .v-btn--active {
      background-color: rgb(81, 135, 224) !important;
    }
  }
}
</style>
