var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-divider", { staticClass: "my-5" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "max-width": "300",
                  hint: "If your version is not in the list, contact our support",
                  "persistent-hint": "",
                  items: _vm.version.list,
                },
                model: {
                  value: _vm.version.selected,
                  callback: function ($$v) {
                    _vm.$set(_vm.version, "selected", $$v)
                  },
                  expression: "version.selected",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                [
                  _c("v-icon", { staticClass: "mr-1" }, [
                    _vm._v("mdi-download"),
                  ]),
                  _vm._v("Download extraction app "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "white--text mt-3",
          attrs: {
            color: "#00aadb",
            loading: _vm.loading,
            disabled: _vm.showSerialInfo,
          },
          on: { click: _vm.getSerial },
        },
        [_vm._v(" Generate my serial ")]
      ),
      _vm.showSerialInfo
        ? _c("QuickbooksSerial", { attrs: { "serial-info": _vm.serialInfo } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }