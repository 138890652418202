<template>
    <v-card elevation="0">
        <v-card-text class="pb-0">
            <!-- Title -->
            <div v-if="hasPermission" class="my-3">
                Select the <b>entities</b> to migrate:
            </div>
            <div v-else class="my-3">
                <b>Entities</b> that will be migrated:
            </div>

            <!-- No selected entity alert -->
            <v-alert
                :value="Object.keys(selectedEntities).length === 0"
                dense
                text
                type="error"
                transition="slide-x-transition"
                >You must select at least one entity to migrate.</v-alert
            >

            <div>
                <!-- Entity selection -->
                <div v-for="entity in entitiesNames" :key="entity" class="mb-4">
                    <div class="d-flex justify-space-between align-center">
                        <!-- Checkbox -->
                        <v-checkbox
                            v-model="entities[entity].to_migrate"
                            :label="entityToLabel[entity]"
                            hide-details
                            :disabled="
                                step !== 1 ||
                                !hasPermission ||
                                (entity === 'sales' &&
                                    !entities.items.to_migrate)
                            "
                            class="mt-0"
                        >
                        </v-checkbox>

                        <!-- Csv upload dialog -->
                        <CsvDialog
                            v-if="
                                isCsvIntegration &&
                                entities[entity].to_migrate &&
                                step === 1
                            "
                            :entity="entity"
                            :entitySelected="entities[entity].to_migrate"
                            :files-required="entitiesFiles[entity]"
                            :uploadDone="
                                entitiesWithCsvUploaded.includes(entity)
                            "
                            :uploadNotFinished="
                                entitiesWithCsvUnfinished.includes(entity)
                            "
                            @file-uploaded="
                                entitiesWithCsvUploaded.push(entity)
                            "
                            @file-deleted="updateEntitiesWithCsv"
                        />
                    </div>
                </div>
            </div>
        </v-card-text>

        <!-- Footer -->
        <v-card-actions class="pt-2" v-if="hasPermission">
            <v-spacer></v-spacer>
            <div v-if="step === 1">
                <!-- Save button -->
                <v-btn
                    class="white--text"
                    color="#00aadb"
                    small
                    :loading="loading"
                    @click="saveEntities"
                >
                    <v-icon small class="mr-1">mdi-content-save</v-icon>
                    Save selection
                </v-btn>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import {
    updateMigration,
    getMigration,
} from "../../../database/model/model.migration";
import { getAllCsvInfo } from "../../../database/model/model.migration_csv_info";
import { getAllSchema } from "../../../service/csvIntegrationApi";
import CsvDialog from "./CsvDialog.vue";

export default {
    name: "EntitiesSelection",
    props: {
        step: Number,
        integrationsSnapshots: Object,
        hasPermission: Boolean,
    },
    components: { CsvDialog },
    data() {
        return {
            entitiesFiles: {
                items: ["items"],
                sales: ["sales", "salelines"],
                customers: ["customers"],
                vendors: ["vendors"],
                inventory: ["inventory"],
                images: ["images"],
                gift_cards: ["gift_cards"],
                credit_accounts: ["credit_accounts"],
            },
            entityToLabel: {
                sales: "Sales",
                items: "Items",
                customers: "Customers",
                vendors: "Vendors",
                inventory: "Inventory",
                images: "Images",
                gift_cards: "Gift cards",
                credit_accounts: "Credit accounts",
            },
            entities: {
                items: {
                    to_migrate: !this.hasPermission,
                    entity_name: "items",
                    include_archived: true,
                    identifier: "sku",
                    start_date: "",
                    end_date: "",
                },
                customers: {
                    to_migrate: !this.hasPermission,
                    entity_name: "customers",
                    include_archived: true,
                    identifier: "email",
                    start_date: "",
                    end_date: "",
                },
                sales: {
                    to_migrate: !this.hasPermission,
                    entity_name: "sales",
                    include_archived: true,
                    identifier: "",
                    start_date: "",
                    end_date: "",
                },
                vendors: {
                    to_migrate: !this.hasPermission,
                    entity_name: "vendors",
                    include_archived: true,
                    identifier: "",
                    start_date: "",
                    end_date: "",
                },
                gift_cards: {
                    to_migrate: !this.hasPermission,
                    entity_name: "gift_cards",
                    include_archived: false,
                    identifier: "",
                    start_date: "",
                    end_date: "",
                },
                credit_accounts: {
                    to_migrate: !this.hasPermission,
                    entity_name: "credit_accounts",
                    include_archived: false,
                    identifier: "",
                    start_date: "",
                    end_date: "",
                },
                inventory: {
                    to_migrate: !this.hasPermission,
                    entity_name: "inventory",
                    include_archived: true,
                    identifier: "",
                    start_date: "",
                    end_date: "",
                },
                images: {
                    to_migrate: !this.hasPermission,
                    entity_name: "images",
                    include_archived: false,
                    identifier: "",
                    start_date: "",
                    end_date: "",
                },
            },
            entitiesWithCsvUploaded: [],
            entitiesWithCsvUnfinished: [],
            loading: false,
        };
    },
    computed: {
        // Check if there is a csv type of integration
        isCsvIntegration() {
            const sourceIntegrationId =
                this.$store.state.firestore.user.last_migration.source;
            const sourceIntegration =
                this.$store.state.firestore.integrations.find(
                    (integration) => integration.id === sourceIntegrationId
                );
            return sourceIntegration.name === "csv";
        },
        // Check if there is a quickbooks type of integration
        isQuickbooksIntegration() {
            const sourceIntegrationId =
                this.$store.state.firestore.user.last_migration.source;
            const sourceIntegration =
                this.$store.state.firestore.integrations.find(
                    (integration) => integration.id === sourceIntegrationId
                );
            return sourceIntegration.name === "quickbooks";
        },
        getDestinationIntegrationName() {
            const destinationIntegrationId =
                this.$store.state.firestore.user.last_migration.destination;
            const destinationIntegration =
                this.$store.state.firestore.integrations.find(
                    (integration) => integration.id === destinationIntegrationId
                );
            return destinationIntegration.name;
        },
        getSourceIntegrationName() {
            const sourceIntegrationId =
                this.$store.state.firestore.user.last_migration.source;
            const sourceIntegration =
                this.$store.state.firestore.integrations.find(
                    (integration) => integration.id === sourceIntegrationId
                );
            return sourceIntegration.name;
        },
        // Return an array of entities' names
        entitiesNames() {
            return this.isQuickbooksIntegration
                ? Object.keys(this.entitiesFiles).filter(
                      (integration) => integration !== "gift_cards"
                  )
                : Object.keys(this.entitiesFiles);
        },
        // Build the entities object used when creating/updating a migration
        selectedEntities() {
            let entities = {};
            this.entitiesNames.forEach((entity) => {
                if (this.entities[entity].to_migrate) {
                    if (
                        this.$store.state.firestore.migration?.entities &&
                        this.$store.state.firestore.migration.entities[entity]
                            ?.to_migrate
                    ) {
                        entities[entity] = {
                            ...this.$store.state.firestore.migration?.entities[
                                entity
                            ],
                            ...this.entities[entity],
                        };
                    } else {
                        entities[entity] = {
                            ...this.entities[entity],
                            state: {
                                convert: {
                                    average_time: 0,
                                    count_done: 0,
                                    count_error: 0,
                                    count_total: 0,
                                    is_done: false,
                                    last_migration_id: "",
                                },
                                fetch: {
                                    average_time: 0,
                                    count_done: 0,
                                    count_error: 0,
                                    count_total: 0,
                                    is_done: false,
                                    last_migration_id: "",
                                },
                                push: {
                                    average_time: 0,
                                    count_done: 0,
                                    count_error: 0,
                                    count_total: 0,
                                    is_done: false,
                                    last_migration_id: "",
                                },
                                is_migration_done: false,
                            },
                        };
                    }
                }
            });

            return entities;
        },
        migrationId() {
            return this.$store.state.firestore.user.last_migration.migration;
        },
    },
    methods: {
        async getAllSchema(integrationName) {
            try {
                const res = await getAllSchema(integrationName);
                return res.data;
            } catch (error) {
                console.error(error);
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please reload the page.",
                });
            }
        },
        // Set initial entities values and selected locations if there's a current migration
        initiateSetup() {
            const currentMigration = this.$store.state.firestore.migration;

            this.entitiesNames.forEach((entity) => {
                if (currentMigration.entities[entity]) {
                    this.entities[entity] = currentMigration.entities[entity];
                }
            });
        },
        // Check the csv info for that migration and determine if csv is uploaded or unfinished
        async checkCsvInfo() {
            const allCsvInfo = await getAllCsvInfo({
                user_id: this.$store.state.firestore.id,
                migration_id: this.migrationId,
            });

            allCsvInfo.forEach((csvInfo) => {
                csvInfo.is_confirm
                    ? this.entitiesWithCsvUploaded.push(csvInfo.entity_name)
                    : this.entitiesWithCsvUnfinished.push(csvInfo.entity_name);
            });
        },
        // Reset entities and locations
        reset() {
            this.entities = {
                items: {
                    ...this.entities.items,
                    to_migrate: !this.hasPermission,
                    include_archived: false,
                    start_date: "",
                    end_date: "",
                    identifier: "sku",
                },
                customers: {
                    ...this.entities.customers,
                    to_migrate: !this.hasPermission,
                    include_archived: false,
                    identifier: "email",
                },
                sales: {
                    ...this.entities.sales,
                    to_migrate: !this.hasPermission,
                    start_date: "",
                },
                vendors: {
                    ...this.entities.vendors,
                    to_migrate: !this.hasPermission,
                },
                gift_cards: {
                    ...this.entities.gift_cards,
                    to_migrate: !this.hasPermission,
                },
                credit_accounts: {
                    ...this.entities.credit_accounts,
                    to_migrate: !this.hasPermission,
                },
                inventory: {
                    ...this.entities.inventory,
                    to_migrate: !this.hasPermission,
                },
                images: {
                    ...this.entities.images,
                    to_migrate: !this.hasPermission,
                },
            };
            this.entitiesWithCsvUploaded = [];
        },
        // Remove entity from lists after upload cancelled
        updateEntitiesWithCsv(entityName) {
            this.entitiesWithCsvUploaded = this.entitiesWithCsvUploaded.filter(
                (entity) => entity !== entityName
            );
            this.entitiesWithCsvUnfinished =
                this.entitiesWithCsvUnfinished.filter(
                    (entity) => entity !== entityName
                );
        },
        async saveEntities() {
            this.loading = true;
            try {
                const { source_snapshot, destination_snapshot } =
                    this.integrationsSnapshots;

                const payload = {
                    entities: this.selectedEntities,
                    source_snapshot,
                    destination_snapshot,
                };

                // Validate input with schema.
                // Some entity create child entity. They can not be selected here
                // but they are generated during the migration
                const [allSchema, fetchMigration] = await Promise.all([
                    this.getAllSchema(this.getDestinationIntegrationName),
                    getMigration(
                        this.$store.state.firestore.id,
                        this.$store.state.firestore.user.last_migration
                            .migration
                    ),
                ]);
                const fetchMigrationData = fetchMigration.data();
                for (const entityKey of Object.keys(payload.entities)) {
                    const avticeSchemaKey = allSchema[entityKey];
                    if (avticeSchemaKey) {
                        for (const entityToAccountFor of avticeSchemaKey.generated_entity) {
                            const entityToAccountForInBody =
                                fetchMigrationData.entities[entityToAccountFor];
                            if (entityToAccountForInBody) {
                                payload.entities[entityToAccountFor] =
                                    entityToAccountForInBody;
                            }
                        }
                    }
                }

                await updateMigration(
                    this.$store.state.firestore.id,
                    this.$store.state.firestore.user.last_migration.migration,
                    payload
                );
                this.$emit("saved");
                this.$store.dispatch("setSnackbar", {
                    type: "success",
                    text: `Entities saved.`,
                });
            } catch (error) {
                this.$store.dispatch("setSnackbar", {
                    type: "error",
                    text: "Something went wrong. Please try again.",
                });
                console.log("error ->", error);
            } finally {
                this.loading = false;
            }
        },
    },
    created() {
        const currentMigration = this.$store.state.firestore.migration;
        if (
            currentMigration &&
            Object.keys(currentMigration.entities).length > 0
        ) {
            this.initiateSetup();
            this.checkCsvInfo();
        } else if (currentMigration) {
            this.checkCsvInfo();
        }
    },
};
</script>

<style></style>
