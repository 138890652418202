var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.uploadDone || _vm.uploadNotFinished
    ? _c("CsvDelete", {
        attrs: {
          "upload-status": _vm.uploadDone ? "done" : "unfinished",
          entity: _vm.entity,
          "files-required": _vm.filesRequired,
        },
        on: {
          "file-deleted": function ($event) {
            return _vm.$emit("file-deleted", _vm.entity)
          },
        },
      })
    : _vm.entitySelected
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "1100" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "white--text ml-5",
                          class: { "mt-1": _vm.entity === "salelines" },
                          attrs: { color: "#00aadb", "x-small": "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" Upload " + _vm._s(_vm.entity) + " file ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-stepper",
                {
                  attrs: { elevation: "0" },
                  model: {
                    value: _vm.step,
                    callback: function ($$v) {
                      _vm.step = $$v
                    },
                    expression: "step",
                  },
                },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _vm._l(_vm.filesRequired, function (file, index) {
                        return [
                          index !== 0
                            ? _c("v-divider", { key: index + "a" })
                            : _vm._e(),
                          _c(
                            "v-stepper-step",
                            {
                              key: index + "b",
                              attrs: {
                                step: 1 + index * 3,
                                complete: _vm.step > 1 + index * 3,
                              },
                            },
                            [_vm._v("Upload")]
                          ),
                          _c("v-divider", { key: index + "c" }),
                          _c(
                            "v-stepper-step",
                            {
                              key: index + "d",
                              attrs: {
                                step: 2 + index * 3,
                                complete: _vm.step > 2 + index * 3,
                              },
                            },
                            [_vm._v("Match columns")]
                          ),
                          _c("v-divider", { key: index + "e" }),
                          _c(
                            "v-stepper-step",
                            {
                              key: index + "f",
                              attrs: {
                                step: 3 + index * 3,
                                complete: _vm.step > 3 + index * 3,
                              },
                            },
                            [_vm._v("Review")]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-stepper-items",
                    _vm._l(_vm.filesRequired, function (file, index) {
                      return _c(
                        "div",
                        { key: file },
                        [
                          _c(
                            "v-stepper-content",
                            { attrs: { step: 1 + index * 3 } },
                            [
                              _c("CsvUpload", {
                                ref: "csvUpload",
                                refInFor: true,
                                attrs: {
                                  entity: file,
                                  "is-first-file": index === 0,
                                  "files-required": _vm.filesRequired,
                                },
                                on: {
                                  "file-confirmed": _vm.setFile,
                                  "close-dialog": function ($event) {
                                    _vm.show = false
                                  },
                                  cancel: _vm.cancel,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: 2 + index * 3 } },
                            [
                              _c("ColumnMatch", {
                                attrs: {
                                  entity: file,
                                  fileData: _vm.fileData,
                                  "files-required": _vm.filesRequired,
                                },
                                on: {
                                  next: _vm.setValidationResult,
                                  "close-dialog": function ($event) {
                                    _vm.show = false
                                  },
                                  cancel: _vm.cancel,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: 3 + index * 3 } },
                            [
                              _vm.fileIsValid
                                ? _c("CsvValid", {
                                    attrs: {
                                      entity: file,
                                      valid: _vm.fileIsValid,
                                      "files-required": _vm.filesRequired,
                                      "is-last-file":
                                        index + 1 === _vm.filesRequired.length,
                                    },
                                    on: {
                                      "file-uploaded": _vm.fileUploaded,
                                      next: function ($event) {
                                        _vm.step++
                                      },
                                      cancel: _vm.cancel,
                                    },
                                  })
                                : _c("CsvError", {
                                    attrs: {
                                      entity: file,
                                      path: _vm.validationPaths.error_path,
                                      "files-required": _vm.filesRequired,
                                    },
                                    on: { cancel: _vm.cancel },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }