var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "d-flex flex-column pa-5" },
    [
      _c("v-card-title", { staticClass: "justify-center" }, [
        _vm._v(" 1. Configure your migration "),
      ]),
      _c(
        "v-card-text",
        { staticClass: "pb-0" },
        [
          _c("LocationsMapping", {
            ref: "locationsMapping",
            attrs: {
              step: _vm.step,
              "integrations-snapshots": _vm.integrationsSnapshots,
            },
            on: { saved: _vm.onSavedNextStep },
          }),
          _vm.hasEntitiesSelectionPermission ? _c("v-divider") : _vm._e(),
          _c("EntitiesSelection", {
            ref: "entitiesSelection",
            attrs: {
              "has-permission": _vm.hasEntitiesSelectionPermission,
              step: _vm.step,
              "integrations-snapshots": _vm.integrationsSnapshots,
            },
            on: { saved: _vm.onSavedNextStep },
          }),
          _vm.step > 1
            ? _c(
                "div",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "x-small": "",
                        disabled: _vm.disableChangeButton,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:step", 1)
                        },
                      },
                    },
                    [_vm._v(" Change configuration ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }