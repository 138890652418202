var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-5 d-flex flex-column" },
    [
      _c("v-card-title", { staticClass: "justify-center" }, [
        _vm._v(" 3. Follow the migration progress "),
      ]),
      _vm.step === 2 &&
      (_vm.status === "RUNNING" ||
        _vm.status === "DONE" ||
        _vm.status === "QUEUED" ||
        _vm.status === "PAUSED")
        ? _c(
            "div",
            [
              _c(
                "v-card-text",
                [
                  _c("div", { staticClass: "mb-5" }, [
                    _vm.status === "DONE"
                      ? _c("h4", [_vm._v("Migration complete")])
                      : _c("div", [
                          _c("h4", [_vm._v("Time before completion:")]),
                          _c("h2", [
                            _vm._v(
                              _vm._s(_vm.migrationProgress.timeLeft) +
                                " seconds"
                            ),
                          ]),
                        ]),
                  ]),
                  _c(
                    "v-progress-linear",
                    {
                      staticClass: "mb-5",
                      attrs: {
                        value: _vm.migrationProgress.percentage,
                        height: "25",
                        rounded: "",
                        color: "#00aadb",
                      },
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.migrationProgress.percentage) + "%"),
                      ]),
                    ]
                  ),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.entitiesProgress,
                      "hide-default-footer": "",
                      "sort-by": "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.fetch",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center align-center my-1",
                                },
                                [
                                  item.fetch.status === "NOT_STARTED"
                                    ? _c("v-icon", [_vm._v("mdi-minus")])
                                    : _vm._e(),
                                  item.fetch.status === "DONE"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("mdi-check")]
                                      )
                                    : item.fetch.status === "RUNNING" &&
                                      _vm.status === "PAUSED"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "warning" } },
                                        [_vm._v("mdi-pause")]
                                      )
                                    : item.fetch.status === "RUNNING"
                                    ? _c("v-progress-circular", {
                                        staticClass: "mb-1",
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary",
                                          size: "20",
                                          width: "3",
                                        },
                                      })
                                    : _vm._e(),
                                  item.fetch.status === "DONE" ||
                                  item.fetch.status === "RUNNING"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.fetch.count_done) +
                                            " / " +
                                            _vm._s(item.fetch.count_total)
                                        ),
                                      ])
                                    : _vm._e(),
                                  item.fetch.status === "DONE" ||
                                  item.fetch.status === "RUNNING"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.fetch.count_error) +
                                            " error(s)"
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.convert",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center align-center my-1",
                                },
                                [
                                  item.convert.status === "NOT_STARTED"
                                    ? _c("v-icon", [_vm._v("mdi-minus")])
                                    : _vm._e(),
                                  item.convert.status === "DONE"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("mdi-check")]
                                      )
                                    : item.convert.status === "RUNNING" &&
                                      _vm.status === "PAUSED"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "warning" } },
                                        [_vm._v("mdi-pause")]
                                      )
                                    : item.convert.status === "RUNNING"
                                    ? _c("v-progress-circular", {
                                        staticClass: "mb-1",
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary",
                                          size: "20",
                                          width: "3",
                                        },
                                      })
                                    : _vm._e(),
                                  item.convert.status === "DONE" ||
                                  item.convert.status === "RUNNING"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.convert.count_done) +
                                            " / " +
                                            _vm._s(item.convert.count_total)
                                        ),
                                      ])
                                    : _vm._e(),
                                  item.convert.status === "DONE" ||
                                  item.convert.status === "RUNNING"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.convert.count_error) +
                                            " error(s)"
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.push",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center align-center my-1",
                                },
                                [
                                  item.push.status === "NOT_STARTED"
                                    ? _c("v-icon", [_vm._v("mdi-minus")])
                                    : _vm._e(),
                                  item.push.status === "DONE"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("mdi-check")]
                                      )
                                    : item.push.status === "RUNNING" &&
                                      _vm.status === "PAUSED"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "warning" } },
                                        [_vm._v("mdi-pause")]
                                      )
                                    : item.push.status === "RUNNING"
                                    ? _c("v-progress-circular", {
                                        staticClass: "mb-1",
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary",
                                          size: "20",
                                          width: "3",
                                        },
                                      })
                                    : _vm._e(),
                                  item.push.status === "DONE" ||
                                  item.push.status === "RUNNING"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.push.count_done) +
                                            " / " +
                                            _vm._s(item.push.count_total)
                                        ),
                                      ])
                                    : _vm._e(),
                                  item.push.status === "DONE" ||
                                  item.push.status === "RUNNING"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.push.count_error) +
                                            " error(s)"
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.download",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.isEntityDisabled(item),
                                    loading:
                                      _vm.loading[
                                        item.name + "_download_error"
                                      ],
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadErrorFile(item.name)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-download")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4100476822
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.status === "DONE"
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    color: "#00aadb",
                    disabled: _vm.migration_errors.disabled,
                    loading: _vm.migration_errors.loading,
                  },
                  on: { click: _vm.fetchErrors },
                },
                [_vm._v("Get record")]
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.migration_errors.headers,
                  items: _vm.migration_errors.values,
                  "items-per-page": 20,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }