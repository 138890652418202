import axios from "axios";
import { getInstance } from "../auth";

export async function getSchema(integrationName, entityName) {
  const token = await getInstance().getTokenSilently();
  return  axios.get(
    `${process.env.VUE_APP_CSV_INTEGRATION_URL}/schema/${integrationName}/${entityName}`, 
    { headers: { "Authorization": `bearer ${token}` } }
  );
}

export async function getAllSchema(integrationName) {
  const token = await getInstance().getTokenSilently();
  return  axios.get(
    `${process.env.VUE_APP_CSV_INTEGRATION_URL}/schema/${integrationName}`, 
    { headers: { "Authorization": `bearer ${token}` } }
  );
}

export async function validateCsv(entityName, payload) {
  const token = await getInstance().getTokenSilently();
  return axios.post(
    `${process.env.VUE_APP_CSV_INTEGRATION_URL}/validate/csv/${entityName}`, 
    payload,
    { headers: { "Authorization": `bearer ${token}` } }
  );
}

export async function deleteCsv(entityName, migrationId) {
  const token = await getInstance().getTokenSilently();
  return axios.delete(
    `${process.env.VUE_APP_CSV_INTEGRATION_URL}/schema/reset-files/${entityName}`, 
    { 
      params: { migration_id: migrationId },
      headers: { "Authorization": `bearer ${token}` } 
    }
  );
}

export async function confirmCsv(entityName, payload) {
  const token = await getInstance().getTokenSilently();
  
  let urlChunkCsv = `${process.env.VUE_APP_CSV_INTEGRATION_URL}/validate/csv-chunk/${entityName}`

  /**
   * TODO: Look for a better way of doing that.
   * We currently chunk salelines in sync with sales to ensure data integrity.
   * It link sales file to correspondic line files.
   * 
   * Possible solution : refactor code to actually pass the value of sales 
   */
  if (entityName === "salelines" ) {
    urlChunkCsv = `${process.env.VUE_APP_CSV_INTEGRATION_URL}/validate/csv-chunk/sales`
  }
  return axios.post(
    urlChunkCsv, 
    payload,
    { headers: { "Authorization": `bearer ${token}` } }
  );
}