import { actions, roles } from "../constants"

const mappings = new Map()

// Migration control
mappings.set(actions.PAUSE_MIGRATION, [roles.ADMIN, roles.USER])
mappings.set(actions.RESUME_MIGRATION, [roles.ADMIN, roles.USER])
mappings.set(actions.CANCEL_MIGRATION, [roles.ADMIN])

// Migration setup
mappings.set(actions.MULTI_LOCATIONS_MAPPING, [roles.ADMIN])
mappings.set(actions.SELECT_ENTITIES, [roles.ADMIN])
mappings.set(actions.NO_MIGRATION_LIMIT, [roles.ADMIN])

// Integrations access
mappings.set(actions.LIGHTSPEED_RETAIL_INTEGRATION, [roles.ADMIN,  roles.LIGHTSPEED_TO_VEND])
mappings.set(actions.QUICKBOOKS_INTEGRATION, [roles.ADMIN, roles.QUICKBOOKS_TO_VEND])
mappings.set(actions.VEND_INTEGRATION, [roles.ADMIN, roles.LIGHTSPEED_TO_VEND, roles.QUICKBOOKS_TO_VEND])
mappings.set(actions.CSV_INTEGRATION, [roles.ADMIN])
mappings.set(actions.SHOPIFY_INTEGRATION, [roles.ADMIN])

// Integrations setup
mappings.set(actions.RESET_INTEGRATION, [roles.ADMIN])

// Administration Permission
mappings.set(actions.ADMIN_PAGE, [roles.ADMIN])


// Check if one of the user's roles is in the list of roles allowed for the action
export default function hasPermission(user, action) {
  const roles = user[`${process.env.VUE_APP_AUTH0_ID_TOKEN_NAMESPACE}/roles`]

  if (!roles || roles.length === 0) return false

  if (mappings.has(action)) {
    return roles.some(role => mappings.get(action).includes(role))
  }

  return false
}