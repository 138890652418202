<template>
    <div class="setup d-flex flex-column pa-8">
        <!-- Disabled page alert is migration ongoing -->
        <DisabledAlert :show="disablePage" />

        <!-- Destination integration selection -->
        <IntegrationSelection
            type="destination"
            :list="availableIntegrations.destination"
            :disabled="disablePage"
        />

        <v-icon size="80" :disabled="disablePage">mdi-arrow-up-bold</v-icon>

        <!-- Source integration selection -->
        <IntegrationSelection
            type="source"
            :list="availableIntegrations.source"
            :is-destination-selected="isDestinationSelected"
            :disabled="disablePage || !isDestinationSelected"
        />
    </div>
</template>

<script>
import IntegrationSelection from "../components/setup/IntegrationSelection.vue";
import DisabledAlert from "../components/setup/DisabledAlert.vue";
import { actions } from "../constants";
import hasPermission from "../service/permissions";

export default {
    name: "IntegrationsSetup",
    components: { IntegrationSelection, DisabledAlert },
    data() {
        return {
            integrationsLists: {
                source: [
                    { text: "Lightspeed Retail", value: "lightspeed_retail" },
                    { text: "Quickbooks", value: "quickbooks" },
                    { text: "CSV files", value: "csv" },
                    // { text: "Resto L", value: "resto_l" }
                ],
                destination: [
                    { text: "Vend (by Lightspeed)", value: "vend" },
                    { text: "Lightspeed Retail", value: "lightspeed_retail" },
                    { text: "Shopify (POS)", value: "shopify" },
                    // { text: "Resto K", value: "resto_k" }
                ],
            },
        };
    },
    computed: {
        // Return list of integrations filtered by permissions
        availableIntegrations() {
            const output = {};
            const types = ["source", "destination"];

            types.forEach((type) => {
                output[type] = this.integrationsLists[type].filter(
                    (integration) =>
                        hasPermission(
                            this.$auth.user,
                            actions[
                                `${integration.value.toUpperCase()}_INTEGRATION`
                            ]
                        )
                );
            });

            return output;
        },
        // Check if a migration is currently ongoing
        disablePage() {
            const disableStatus = [
                "RUNNING",
                "QUEUED",
                "DONE",
                "ERROR",
                "CANCELLED",
                "PAUSED",
            ];
            const migrationStatus =
                this.$store.state.firestore.user.last_migration
                    ?.migration_state;
            return disableStatus.includes(migrationStatus);
        },
        // Return if a destination integration is connected
        isDestinationSelected() {
            return this.$store.state.firestore.user.last_migration.destination
                ? true
                : false;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-card {
    text-align: center;
    width: 750px;
}
</style>

<style scoped lang="scss">
.setup {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>
