<template>
  <NotAllowed v-if="!notAllowed" text="A source and/or destination integration is missing" path="setup" />

  <div v-else class="migration mt-5 d-flex flex-column justify-center align-center">
    <Configuration ref="migrationConfig" :step.sync="step" :status="migrationStatus" @add-listener="addMigrationListener"/>
    <Controls :step="step" :status="migrationStatus" @reset="reset"/>
    <Progress :step="step" :status="migrationStatus" />
  </div>
</template>

<script>
import { listenMigration } from "../database/model/model.migration";
import { listenUser } from "../database/model/model.user";
import Configuration from "../components/migration/Configuration";
import Controls from "../components/migration/Controls";
import Progress from "../components/migration/Progress";
import NotAllowed from "../components/migration/NotAllowed.vue";

export default {
  name: "Migration",
  components: { Configuration, Controls, Progress, NotAllowed },
  data() {
    return {
      notAllowed: true,
      step: 1,
      firebaseListeners: {
        user: null,
        migration: null,
      },
    };
  },
  computed: {
    // Shorthand to get the migration's state
    migrationStatus() {
      return this.$store.state.firestore.user.last_migration?.migration_state;
    },
  },
  methods: {
    addMigrationListener() {
      this.firebaseListeners.migration = listenMigration(
        this.$store.state.firestore.id, 
        this.$store.state.firestore.user.last_migration?.migration
      );
    },
    // Reset view to its initial set without a current migration
    reset() {
      this.$refs.migrationConfig.reset();
      this.step = 1;

      this.firebaseListeners.migration();
    }
  },
  async created() {
    // Check if user has both integrations
    const currentMigration = this.$store.state.firestore.user.last_migration
    if (!currentMigration.source || !currentMigration.destination) {
      this.notAllowed = false;
      return
    }
    // Add Firebase listener for user changes, specifically for user.last_migration
    this.firebaseListeners.user = listenUser(this.$store.state.firestore.id);
    
    // Move to step 2 if applicable
    const stepTwoStatus = ["RUNNING", "PAUSED", "DONE", "QUEUED", "CANCELLED", "ERROR"]
    if (stepTwoStatus.includes(this.migrationStatus)) this.step = 2;

    // If existing current migration, add Firebase listener
    if (this.$store.state.firestore.migration) this.addMigrationListener();

  },
  destroyed() {
    // Remove firebase listeners
    if (this.firebaseListeners.migration) this.firebaseListeners.migration();
    if (this.firebaseListeners.user) this.firebaseListeners.user();
  },
};
</script>

<style scoped lang="scss">
.migration {
  height: 100%;
}
</style>