// eslint-disable-next-line no-unused-vars
import { collection, getDocs } from "firebase/firestore";
import { USERS_COLLECTION_NAME } from "./model.user";
import { db } from "../../service/firebaseConfig";
import { MIGRATION_COLLECTION_NAME } from "./model.migration"

export const MIGRATION_SCHEMA_VERSION = '0.0.1'
export const MIGRATION_COLLECTION_NAME_ERROR = 'migration_error'

export async function getMigrationError(user_id, migration_id) {

  const col = collection(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, migration_id, MIGRATION_COLLECTION_NAME_ERROR)

  const querySnapshot = await getDocs(col)

  const formated_data = []

  querySnapshot.forEach((doc) => {
    const data = doc.data()

    formated_data.push(
      {
        'id': doc.id,
        ...data
      }
    )
  })

  return formated_data
}