<template>
  <div class="d-flex flex-column align-center"> 
    <v-icon color="success" size="150">mdi-check-circle-outline</v-icon>
    <p>The csv file is validated.</p>
    <div class="align-self-end pa-1">
      <v-btn :loading="loading.cancel" @click="showConfirm = true">Cancel</v-btn>
      <v-btn 
        v-if="isLastFile" 
        class="white--text ml-2" 
        color="#00aadb" 
        :loading="loading.confirm" 
        @click="onClickConfirm"
      >
        Confirm
      </v-btn>
      <v-btn 
        v-else 
        class="white--text ml-2" 
        color="#00aadb" 
        :loading="loading.nextFile" 
        @click="onClickConfirmGoNext"
      >
        Next
      </v-btn>
      <ActionConfirmation
        text="Are you sure? Your upload progress will be lost."
        button-text="cancel upload"
        :open="showConfirm"
        @back="showConfirm = false"
        @confirm="cancel"
      />
    </div>
  </div>
</template>

<script>
import { updateCsvInfo } from "../../../database/model/model.migration_csv_info";
import { confirmCsv } from "../../../service/csvIntegrationApi";
import ActionConfirmation from "../../ui/ActionConfirmation.vue"

export default {
  name: "CsvValid",
  components: { ActionConfirmation },
  props: { entity: String, valid: Boolean, isLastFile: Boolean, filesRequired: Array },
  data() {
    return {
      showConfirm: false,
      loading: {
        confirm: false,
        cancel: false,
        nextFile: false
      }
    }
  },
  computed: {
    // Return the id of the current migration
    migrationId() {
      return this.$store.state.firestore.user.last_migration.migration;
    },
    // Return the name of the selected destination integration
    destinationIntegrationName() {
      const state = this.$store.state.firestore;
      const destinationIntegration = state.integrations.find(integration => integration.id === state.user.last_migration.destination);
      return destinationIntegration.name;
    },
  },
  methods: {
    // Set csv_info is_confirm to true in firebase
    confirmCsvInfo() {
      const payload = {
        user_id: this.$store.state.firestore.id,
        migration_id: this.migrationId,
        entity_name: this.entity,
        updated_csv_info: { is_confirm: true }
      };
      return updateCsvInfo(payload);
    },
    // Validate csv-chunk
    confirmCsvFile() {
      const payload = {
        integration_name: this.destinationIntegrationName,
        migration_id: this.migrationId,
        source_id: this.$store.state.firestore.user.last_migration.source,
        destination_id: this.$store.state.firestore.user.last_migration.destination
      }
      return confirmCsv(this.entity, payload);
    },
    // Used when their is more than one file to upload
    async onClickConfirmGoNext() {
      try {
        this.loading.nextFile = true
        await this.confirmCsvInfo();

        this.$emit('next');
      }catch (error) { 
        console.error(error);
        this.$store.dispatch("setSnackbar", { type: "error", text: "Confirmation Failed" });
      }
      finally {
        this.loading.nextFile = false
      }
    },
    // Update csv info in firebase then confirm file
    async onClickConfirm() {
      this.loading.confirm = true;
      try {
        await this.confirmCsvInfo();
        await this.confirmCsvFile();
        
        this.$emit("file-uploaded");
      } catch (error) {
        console.error(error);
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
      } finally {
        this.loading.confirm = false;
      }
    },
    // Cancel upload and delete files
    async cancel() {
      this.showConfirm = false;
      this.loading.cancel = true;
      this.$emit("cancel", () => this.loading.cancel = false);
    }
  }
}
</script>

<style>

</style>