<template>
  <div class="d-flex flex-column align-center"> 
    <v-icon color="error" size="150">mdi-close-circle-outline</v-icon>
    <p>The csv file could not be validated.</p>
    <div class="align-self-end pa-1">
      <v-btn 
        :loading="loading.download" 
        :disabled="path === ''" 
        @click="downloadError"
      >
        <v-icon small class="mr-1">mdi-download</v-icon>
        Download errors file
      </v-btn>
      <v-btn 
        class="white--text ml-2" 
        color="#00aadb" 
        :loading="loading.close" 
        @click="close"
      >
        Close & restart
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref, getDownloadURL } from "firebase/storage";
import { firebaseStorage } from "../../../service/firebaseConfig";

export default {
  name: "CsvError",
  props: { path: String, entity: String, filesRequired: Array },
  data() {
    return {
      loading: {
        download: false,
        close: false,
      }
    }
  },
  methods: {
    // Get the download URL of the error file and downloads it
    async downloadError() {
      this.loading.download = true;
      try { 
        const url = await getDownloadURL(ref(firebaseStorage, this.path));

        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none')
        a.href = url
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()
      } catch (error) { 
        console.error(error);
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
      } finally {
        this.loading.download = false;
      }
    },
    // Delete csv files in firestore and close dialog
    async close() {
      this.showConfirm = false;
      this.loading.close = true;
      this.$emit("cancel", () => this.loading.close = false);
    }
  }
}
</script>

<style>

</style>