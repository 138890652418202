<template>
  <!-- Delete csv button -->
  <CsvDelete 
    v-if="uploadDone || uploadNotFinished" 
    :upload-status="uploadDone ? 'done' : 'unfinished'" 
    :entity="entity" 
    :files-required="filesRequired"
    @file-deleted="$emit('file-deleted', entity)" 
  />

  <!-- Upload csv dialog -->
  <v-dialog v-else-if="entitySelected" v-model="show" max-width="1100">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="white--text ml-5"
        :class="{ 'mt-1': entity === 'salelines' }"
        color="#00aadb"
        v-bind="attrs"
        v-on="on"
        x-small
      >
        Upload {{ entity }} file
      </v-btn>
    </template>

      <v-card>
        <v-stepper v-model="step" elevation="0">
          <v-stepper-header>
            <!-- Add Upload/Mapping/Review columns for each file required to upload -->
            <template v-for="(file, index) in filesRequired">
              <v-divider v-if="index !== 0" :key="index + 'a'"></v-divider>
              <v-stepper-step :step="1 + index * 3" :complete="step > 1 + index * 3" :key="index + 'b'">Upload</v-stepper-step>
              <v-divider :key="index + 'c'"></v-divider>
              <v-stepper-step :step="2 + index * 3" :complete="step > 2 + index * 3" :key="index + 'd'">Match columns</v-stepper-step>
              <v-divider :key="index + 'e'"></v-divider>
              <v-stepper-step :step="3 + index * 3" :complete="step > 3 + index * 3" :key="index + 'f'">Review</v-stepper-step>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <div v-for="(file, index) in filesRequired" :key="file">
              <v-stepper-content :step="1 + index * 3">
                <CsvUpload 
                  ref="csvUpload"
                  :entity="file" 
                  :is-first-file="index === 0"
                  :files-required="filesRequired"
                  @file-confirmed="setFile" 
                  @close-dialog="show = false" 
                  @cancel="cancel" 
                />
              </v-stepper-content>
              <v-stepper-content :step="2 + index * 3">
                <ColumnMatch 
                  :entity="file" 
                  :fileData="fileData" 
                  :files-required="filesRequired"
                  @next="setValidationResult" 
                  @close-dialog="show = false" 
                  @cancel="cancel" 
                />
              </v-stepper-content>
              <v-stepper-content :step="3 + index * 3">
                <CsvValid 
                  v-if="fileIsValid" 
                  :entity="file" 
                  :valid="fileIsValid" 
                  :files-required="filesRequired"
                  :is-last-file="index + 1 === filesRequired.length"
                  @file-uploaded="fileUploaded" 
                  @next="step++" 
                  @cancel="cancel" 
                />
                <CsvError 
                  v-else
                  :entity="file" 
                  :path="validationPaths.error_path"
                  :files-required="filesRequired"
                  @cancel="cancel"
                />
              </v-stepper-content>
            </div>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
</template>

<script>
import { deleteCsv } from "../../../service/csvIntegrationApi";
import { getCsvInfo } from "../../../database/model/model.migration_csv_info";
import CsvDelete from "./CsvDelete.vue";
import CsvUpload from "./CsvUpload.vue";
import ColumnMatch from "./ColumnMatch.vue";
import CsvValid from "./CsvValid.vue";
import CsvError from "./CsvError.vue";

export default {
  name: "CsvDialog",
  components: { CsvUpload, ColumnMatch, CsvDelete, CsvValid, CsvError },
  props: {
    entity: String,
    filesRequired: Array,
    entitySelected: Boolean,
    uploadDone: Boolean,
    uploadNotFinished: Boolean
  },
  data() {
    return {
      show: false,
      step: 1,
      fileData: {},
      validationPaths: {
        validated_path: "",
        error_path: "",
      }
    }
  },
  computed: {
    // Return is file is validated by backend or not
    fileIsValid() {
      return this.validationPaths.error_path === '';
    },
    migrationId() {
      return this.$store.state.firestore.user.last_migration.migration
    },
  },
  methods: {
    setFile(fileData) {
      this.fileData = fileData;
      this.step++;
    },
    setValidationResult(payload) {
      this.validationPaths = payload;
      this.step++
    },
    // Close dialog once file uploaded
    fileUploaded() {
      this.show = false;
      this.$emit('file-uploaded');
    },
    // Delete csv files and csv info in firebase
    async cancel(undoLoadingCallback) {
      if (undoLoadingCallback) {
        try {
          for (const file of this.filesRequired) {
            const csvInfo = await getCsvInfo({
              user_id: this.$store.state.firestore.id,
              migration_id: this.migrationId,
              csv_info: file,
            })
            if (csvInfo.data()) await deleteCsv(file, this.migrationId);
          }
          this.resetData();
        } catch (error) {
          console.error(error);
          this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
        } finally {
          undoLoadingCallback()
        }
      } else {
        this.resetData();
      }
    },
    // Reset data to their default values
    resetData() {
      this.show = false;
      this.step = 1;
      this.fileData = {};
      this.validationPaths = {
        validated_path: "",
        error_path: "",
      }
      this.$refs.csvUpload.forEach(component => component.reset())
    }
  }
}
</script>

<style>

</style>