var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", dense: "", elevation: "4", color: "#fff" } },
    [
      _c("v-app-bar-nav-icon", {
        on: {
          click: function ($event) {
            return _vm.$emit("show-sidebar")
          },
        },
      }),
      _c(
        "v-toolbar-title",
        { staticClass: "d-flex align-center" },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { color: "black" } }, [
            _vm._v("mdi-" + _vm._s(_vm.iconName)),
          ]),
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$route.name)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }