var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.items,
      options: { itemsPerPage: 10 },
      "loading-text": "Loading...",
      "item-key": "id",
      loading: _vm.loading,
      "show-expand": "",
      "single-expand": "",
    },
    on: { "item-expanded": _vm.getMigrationById },
    scopedSlots: _vm._u([
      {
        key: "expanded-item",
        fn: function (ref) {
          var headers = ref.headers
          return [
            _c(
              "td",
              {
                staticClass: "expanded-table",
                attrs: { colspan: headers.length },
              },
              [
                _c("MigrationsDetails", {
                  attrs: {
                    headers: _vm.expanded.headers,
                    items: _vm.expanded.items,
                    loading: _vm.expanded.loading,
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }