var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      attrs: {
        value: _vm.show,
        dense: "",
        text: "",
        type: "error",
        width: "750",
        "max-width": "100%",
      },
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { staticClass: "grow" }, [
            _vm._v(
              " You cannot modify your integrations while a migration is ongoing. "
            ),
          ]),
          _c(
            "v-col",
            { staticClass: "shrink" },
            [
              _c("v-btn", { attrs: { "x-small": "", to: "/migration" } }, [
                _vm._v("Back to migration page"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }