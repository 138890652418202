var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-5 d-flex flex-column" },
    [
      _c("v-card-title", { staticClass: "justify-center" }, [
        _vm._v("2. Start the migration"),
      ]),
      _vm.step === 2
        ? _c(
            "div",
            [
              _vm.showButtons === "new"
                ? _c(
                    "v-card-text",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", loading: _vm.loading.new },
                          on: { click: _vm.createNewMigration },
                        },
                        [_vm._v("Start new migration")]
                      ),
                    ],
                    1
                  )
                : _vm.showButtons === "pause/cancel/resume"
                ? _c(
                    "v-card-text",
                    [
                      _vm.status === "PAUSED" && _vm.canResume
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: { dark: "", loading: _vm.loading.resume },
                              on: {
                                click: function ($event) {
                                  return _vm.migrationAction("resume")
                                },
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-play")]),
                              _vm._v("Resume "),
                            ],
                            1
                          )
                        : _vm.canPause
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-5",
                              attrs: {
                                color: "warning",
                                loading: _vm.loading.pause,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.migrationAction("pause")
                                },
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-pause")]),
                              _vm._v("Pause "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.canCancel
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "error",
                                loading: _vm.loading.cancel,
                              },
                              on: { click: _vm.cancelMigration },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-close-thick")]),
                              _vm._v("Cancel "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : !_vm.dailyTopUps
                ? _c(
                    "v-card-text",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", loading: _vm.loading.start },
                          on: {
                            click: function ($event) {
                              _vm.showConfirm = true
                            },
                          },
                        },
                        [_vm._v("Start migration")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("ActionConfirmation", {
        attrs: {
          text: "Are you sure you're ready to migrate? The decision to start a migration is final.",
          "button-text": "Start migration",
          "button-color": "#00aadb",
          open: _vm.showConfirm,
        },
        on: {
          back: function ($event) {
            _vm.showConfirm = false
          },
          confirm: function ($event) {
            return _vm.migrationAction("start")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }