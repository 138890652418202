var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-5" }, [
    _c(
      "div",
      { staticClass: "d-flex align-center" },
      [
        _c("v-text-field", {
          attrs: {
            readonly: "",
            outlined: "",
            dense: "",
            value: _vm.serialInfo.id,
          },
          scopedSlots: _vm._u([
            {
              key: "append-outer",
              fn: function () {
                return [
                  _c("v-btn", { attrs: { small: "", text: "" } }, [
                    _vm._v("Copy"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex flex-column align-center" },
      [
        _c("v-text-field", {
          attrs: {
            value: _vm.serialInfo.status,
            filled: "",
            dense: "",
            label: "Serial status",
            readonly: "",
          },
        }),
        _c("v-text-field", {
          attrs: {
            value: _vm.serialInfo.application_status,
            filled: "",
            dense: "",
            label: "Application status",
            readonly: "",
          },
        }),
        _c("v-text-field", {
          attrs: {
            value: _vm.lastMigration,
            filled: "",
            dense: "",
            label: "Last migration",
            readonly: "",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }