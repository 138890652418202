<template>
  <v-snackbar
    v-model="show"
    timeout="3000"
    :color="type"
    text
    elevation="10"
  >
    <div class="d-flex align-center">
      <v-icon :color="type">{{ type === "success" ? "mdi-check" : "mdi-alert-octagon"}}</v-icon>
      <span class="ml-3">{{ text }}</span>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  computed: {
    show: {
      get() {
        return this.$store.state.snackbar.show;
      },
      set() {
        this.$store.dispatch("setSnackbar", "close")
      }
    },
    text() {
      return this.$store.state.snackbar.text;
    },
    type() {
      return this.$store.state.snackbar.type;
    }
  }
}
</script>

<style>

</style>