<template>
<div class="mt-5">
  <div class="d-flex align-center">
    <v-text-field
      readonly
      outlined
      dense
      :value="serialInfo.id"
    >
      <template v-slot:append-outer>
        <v-btn small text>Copy</v-btn>
      </template>
    </v-text-field>
  </div>

  <div class="d-flex flex-column align-center">

      <v-text-field
        :value="serialInfo.status"
        filled
        dense
        label="Serial status"
        readonly
      ></v-text-field>
      <v-text-field
        :value="serialInfo.application_status"
        filled
        dense
        label="Application status"
        readonly
      ></v-text-field>
      <v-text-field
        :value="lastMigration"
        filled
        dense
        label="Last migration"
        readonly
      ></v-text-field>
  </div>
  </div>
</template>

<script>
export default {
  name: "QuickbooksSerial",
  props: { serialInfo: Object },
  data() {
    return  {
      lastMigration: "-"
    }
  }
}
</script>

<style scoped>
.v-text-field__details {
  display:none;
}
</style>