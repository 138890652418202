var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.notAllowed
    ? _c("NotAllowed", {
        attrs: {
          text: "A source and/or destination integration is missing",
          path: "setup",
        },
      })
    : _c(
        "div",
        {
          staticClass:
            "migration mt-5 d-flex flex-column justify-center align-center",
        },
        [
          _c("Configuration", {
            ref: "migrationConfig",
            attrs: { step: _vm.step, status: _vm.migrationStatus },
            on: {
              "update:step": function ($event) {
                _vm.step = $event
              },
              "add-listener": _vm.addMigrationListener,
            },
          }),
          _c("Controls", {
            attrs: { step: _vm.step, status: _vm.migrationStatus },
            on: { reset: _vm.reset },
          }),
          _c("Progress", {
            attrs: { step: _vm.step, status: _vm.migrationStatus },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }