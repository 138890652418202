// import { getStorage } from "firebase/storage";
// import { addDoc, collection, doc, getDocs } from "firebase/firestore"; 

// eslint-disable-next-line no-unused-vars
import { doc, setDoc, getDoc, DocumentData, DocumentReference, updateDoc, onSnapshot } from "firebase/firestore"; 
import store from "../../store";
import { db } from "../../service/firebaseConfig";

export const USER_SCHEMA_VERSION  = '0.0.1'
export const USERS_COLLECTION_NAME  = 'users'

/**
 * Create a user.
 * @param {{email:str, register_email:str}} user - `null` removes the current converter.
 * @param {String} id Id use to identify the user. SHould be the same as the one in the auth
 * @param {DocumentReference<DocumentData>} user_doc
 */
export async function createUser(user,id,user_doc=null){
    if (!user_doc) {
        user_doc = doc(db, USERS_COLLECTION_NAME, id)
    }

    let new_user = {
        '_nkdv_': USER_SCHEMA_VERSION,
        'email':user.email,
        'register_email': user.register_email || user.email,
        'last_migration': {
            'is_running' : false,
            'migration_state': null,
            'migration': null,
            'source': null,
            'destination': null 
        }
    }

    await setDoc(user_doc, new_user)

    return await getDoc(user_doc)
}


/**
 * Create a user if it is a new user
 * @param {{email:str, register_email:str}} user - `null` removes the current converter.
 * @param {String} id Id use to identify the user. SHould be the same as the one in the auth
 */
 export async function createIfNewUser(user, id){
    
    const user_doc = doc(db, USERS_COLLECTION_NAME, id)
    
    let my_user = await getDoc(user_doc)

    if ( !my_user.exists() ) {
        my_user = await createUser(user, id, user_doc)
    }

    return my_user
}

/**
 * Listen to changes in the user collection
 * @param {String} user_id Id use to identify the user. SHould be the same as the one in the auth
 */
export function listenUser(user_id) {
    return onSnapshot(
        doc(db, USERS_COLLECTION_NAME, user_id), 
        doc => store.dispatch("setFirestoreUser", doc.data()),
        error => console.log("Error on listen ->", error) // TODO: What error handling to do, if any?
    )
  }  

/**
 * Update a user
 * @param {Object} update_payload Updated data payload.
 * @param {String} id Id use to identify the user. SHould be the same as the one in the auth
 */
 export async function updateUser(update_payload, id){
    const user_doc = doc(db, USERS_COLLECTION_NAME, id)
        
    return await updateDoc(user_doc, update_payload)
}
