// eslint-disable-next-line no-unused-vars
import { doc, getDocs, setDoc, deleteDoc, updateDoc, collection, getDoc } from "firebase/firestore"; 
import { USERS_COLLECTION_NAME } from "./model.user"; 
import { MIGRATION_COLLECTION_NAME } from "./model.migration"; 
import { db } from "../../service/firebaseConfig";

export const CSV_INFO_SCHEMA_VERSION  = '0.0.1'
export const CSV_INFO_COLLECTION_NAME  = 'csv_info'

/**
 * Create a csv_info collection.
 * @param {String} user_id - Id use to identify the user. SHould be the same as the one in the auth
 * @param {Object} migration_name - Migration object.
*/
export async function createCsvInfo({ user_id, migration_id, entity_name, columns }, path) {
  const csv_info_col = doc(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, migration_id, CSV_INFO_COLLECTION_NAME, entity_name);

  let csv_info = {
    "_nkdv_": CSV_INFO_SCHEMA_VERSION,
    file_origin: "ORIGINAL_FILE",
    entity_name,
    path,
    validated_path: "",
    error_path: "",
    chosen_id: "",
    is_confirm: false,
    columns,
    columns_mapping: {},
    error_found: []
  }
    
  return await setDoc(
    csv_info_col,
    csv_info
  )
}

export async function getCsvInfo({ user_id, migration_id, csv_info_id }) {
  const csvInfoCollection = doc(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, migration_id, CSV_INFO_COLLECTION_NAME, csv_info_id)

  return await getDoc(csvInfoCollection)
}

export async function getAllCsvInfo({ user_id, migration_id }){

  const csvInfoCollection = collection(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, migration_id, CSV_INFO_COLLECTION_NAME)

  const querySnapshot = await getDocs(csvInfoCollection)
  
  const allCsvInfo = []
  querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const data = doc.data()

      allCsvInfo.push(
          {
              ...data
          }
      )
  })

  return allCsvInfo
}

export async function updateCsvInfo({ user_id, migration_id, entity_name, updated_csv_info }) {
  const csv_info_col= doc(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, migration_id,CSV_INFO_COLLECTION_NAME, entity_name);

  return await updateDoc(csv_info_col, updated_csv_info);
}

export async function deleteCsvInfo({ user_id, migration_id, entity_name }) {
  const csv_info_col = doc(db, USERS_COLLECTION_NAME, user_id, MIGRATION_COLLECTION_NAME, migration_id,CSV_INFO_COLLECTION_NAME, entity_name);

  return await deleteDoc(csv_info_col)
}