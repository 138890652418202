var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { disabled: _vm.disabled } },
    [
      _vm.currentIntegration
        ? _c(
            "v-card-title",
            { staticClass: "info lighten-1 text-body-1 white--text py-1 mb-5" },
            [
              _vm._v(" Current connected account: "),
              _c("strong", { staticClass: "ml-1" }, [
                _vm._v(_vm._s(_vm.currentIntegration.account_name)),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "v-card-text",
        [
          !_vm.isDestinationSelected && _vm.type === "source"
            ? _c("v-alert", { attrs: { dense: "", text: "", type: "error" } }, [
                _vm._v("You must connect the destination integration first."),
              ])
            : _vm._e(),
          _c("v-select", {
            attrs: {
              items: _vm.list,
              outlined: "",
              label: _vm.labelText,
              disabled: _vm.loading.connect,
            },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
          _vm.selected === "shopify" && !_vm.isConnected
            ? _c("v-text-field", {
                attrs: { dense: "", label: "Shop Name" },
                model: {
                  value: _vm.extrasUrlInfo,
                  callback: function ($$v) {
                    _vm.extrasUrlInfo = $$v
                  },
                  expression: "extrasUrlInfo",
                },
              })
            : _vm._e(),
          !_vm.isConnected
            ? _c(
                "v-btn",
                {
                  attrs: {
                    dark: "",
                    color: "#00aadb",
                    loading: _vm.loading.connect,
                  },
                  on: { click: _vm.onClickConnect },
                },
                [_vm._v("Connect")]
              )
            : _vm._e(),
          _vm.loading.connect
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-3",
                  attrs: { "x-small": "", text: "" },
                  on: { click: _vm.undoLoading },
                },
                [_vm._v("Retry")]
              )
            : _vm._e(),
          _vm.isConnected
            ? _c(
                "div",
                [
                  _c(
                    "v-btn",
                    { attrs: { disabled: "" } },
                    [
                      _c("v-icon", { staticClass: "mr-3" }, [
                        _vm._v("mdi-check"),
                      ]),
                      _vm._v("Connected"),
                    ],
                    1
                  ),
                  _vm.canReset
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-3",
                          attrs: {
                            "x-small": "",
                            text: "",
                            loading: _vm.loading.reset,
                          },
                          on: { click: _vm.resetIntegration },
                        },
                        [_vm._v(" Reset ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.selected === "csv" && _vm.isConnected
            ? _c("CsvLocations")
            : _vm._e(),
          _vm.isConnected && _vm.selected === "csv"
            ? _c("TaxSettings", { attrs: { "integration-name": _vm.selected } })
            : _vm._e(),
          _vm.isConnected && _vm.selected === "quickbooks"
            ? _c("QuickbooksSetup")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }