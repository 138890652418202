<template>
  <div class="pa-1">
    <!-- File input -->
    <v-file-input 
      :label="`Upload your CSV file for ${entity}`"
      v-model="file"
      accept=".csv"
    ></v-file-input>

    <!-- Preview table -->
    <div class="preview" v-if="preview.headers.length > 0">
      <table>
        <thead>
          <tr>
            <th
              v-for="(header, index) of preview.headers"
              :key="'header' + index"
              class="greyed-cell"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) of preview.items.slice(0, 10)" :key="index">
            <td v-for="(column, index) in item" :key="index">{{ column }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Footer buttons -->
    <div class="d-flex justify-end">
      <v-btn 
        color="error" 
        :loading="loading.cancel" 
        @click="showConfirm = true"
      >
        Cancel
      </v-btn>
      <v-btn class="mx-2" @click="$emit('close-dialog')">Close</v-btn>
      <v-btn 
        class="white--text" 
        color="#00aadb" 
        :disabled="!file" 
        :loading="loading.confirm"
        @click="uploadCsv(entity)" 
      >
        Confirm
      </v-btn>
      <ActionConfirmation
        text="Are you sure? Your upload progress will be lost."
        button-text="cancel upload"
        :open="showConfirm"
        @back="showConfirm = false"
        @confirm="cancel"
      />
    </div>
  </div>
</template>

<script>
import { createCsvInfo } from "../../../database/model/model.migration_csv_info";
import { firebaseStorage } from "../../../service/firebaseConfig";
import { uploadBytes, ref } from "firebase/storage";
import ActionConfirmation from "../../ui/ActionConfirmation.vue"

export default {
  name: "CsvUpload",
  components: { ActionConfirmation },
  props: { entity: String, filesRequired: Array, isFirstFile: Boolean },
  data() {
    return {
      file: null,
      preview: {
        headers: [],
        items: [],
      },
      showConfirm: false,
      loading: {
        confirm: false,
        cancel: false,
      },
    }
  },
  computed: {
    migrationId() {
      return this.$store.state.firestore.user.last_migration.migration
    },
  },
  watch: {
    file(fileData) {
      if (fileData) this.getPreviewData(fileData)
    },
  },
  methods: {
    // Read the csv file
    getPreviewData(fileData) {
      const reader = new FileReader();
      reader.onload =  () => {
          const csvArray = this.csvToArray(reader.result);
          this.preview.headers = csvArray.headers;
          this.preview.items = csvArray.items;
      }
      reader.readAsText(fileData);
    },
    // Format the data from csv FileReader
    csvToArray(string, delimiter = ",") {
      const str = string.replace(/['"]+/g, "");
      const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
      const rows = str.slice(str.indexOf("\n") + 1).split("\n");

      const arr = rows.map((row) => {
        const values = row.slice(0, row.length - 1).split(delimiter);
        return values;
      });

      return { headers, items: arr };
    },
    // Upload CSV to storage avec create csv_info collection/document
    async uploadCsv(entityName) {
      this.loading.confirm = true;
      try {
        const userId = this.$store.state.firestore.id;

        const path = `user/${userId}/user_files/${this.migrationId}/${entityName}.csv`;
        const storageRef = ref(firebaseStorage, path);
        await uploadBytes(storageRef, this.file);

        const payload = {
          user_id: userId,
          migration_id: this.migrationId,
          entity_name: entityName,
          columns: this.preview.headers,
        }
        await createCsvInfo(payload, path);

        this.$emit("file-confirmed", this.preview);
        this.$store.dispatch("setSnackbar", { type: "success", text: "Csv file uploaded." });
      } catch (error) {
        console.error(error)
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
      } finally {
        this.loading.confirm = false;
      }
    },
    // Cancel upload and delete files
    async cancel() {
      this.showConfirm = false;
      if (this.isFirstFile) {
        this.$emit("cancel")
      } else {
        this.loading.cancel = true;
        this.$emit("cancel", () => this.loading.cancel = false);
      }
    },
    // Reset data to their default values
    reset() {
      this.file = null;
      this.preview = {
        headers: [],
        items: []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.preview {
  overflow-x: auto;
  white-space: nowrap;

  table {
    width: 100%;
    margin: 25px 0px;

    .greyed-cell {
      background-color: rgb(233, 233, 233);
    }

    th {
      padding: 0 16px;
      border: thin solid rgba(0, 0, 0, 0.12);
    }

    td {
      border: thin solid rgba(0, 0, 0, 0.12);
      padding: 0 16px;
    }
  }
}
</style>