var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center" },
    [
      _c(
        "v-card",
        {
          staticClass: "mt-5 mx-2 flex-shrink-0",
          attrs: { "min-width": "700", "max-width": "800" },
        },
        [
          _c("v-card-title", { staticClass: "d-flex justify-center" }, [
            _vm._v("Not allowed"),
          ]),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex align-center justify-center" },
              [
                _c("v-icon", { attrs: { color: "error", size: "80" } }, [
                  _vm._v("mdi-alert-circle-outline"),
                ]),
                _c("div", { staticClass: "ml-2" }, [
                  _c("p", [_vm._v(_vm._s(_vm.text) + ".")]),
                  _c("p", [_vm._v("You cannot access this page.")]),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                { attrs: { color: "secondary", to: "/" + _vm.path } },
                [_vm._v("Back to " + _vm._s(_vm.path))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }