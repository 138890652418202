var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-1" },
    [
      _c(
        "div",
        [
          _c("h2", [_vm._v("Select the id column")]),
          _c("p", { staticClass: "text-caption" }, [
            _vm._v(
              " You can use a column that is mapped to a specific entities. "
            ),
            _c("br"),
            _vm._v(
              " * If you select something other than the default id column (ie. "
            ),
            _c("strong", [_vm._v(_vm._s(_vm.defaultId))]),
            _vm._v(" for " + _vm._s(_vm.entity) + "), also "),
            _c("strong", [_vm._v(" " + _vm._s(_vm.defaultId))]),
            _vm._v(
              " is mapped to a header in your csv the choice here will override the value in this column "
            ),
          ]),
          _c("v-select", {
            attrs: {
              items: _vm.allowedIds,
              hint: "Please chose the column to use as the id",
              "persistent-hint": "",
              outlined: "",
              dense: "",
            },
            model: {
              value: _vm.selectedId,
              callback: function ($$v) {
                _vm.selectedId = $$v
              },
              expression: "selectedId",
            },
          }),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-8" }),
      _c("h2", [_vm._v("Map the columns")]),
      _c(
        "v-btn",
        {
          staticClass: "white--text",
          attrs: { color: "#00aadb", loading: _vm.loading.autoMapCol },
          on: {
            click: function ($event) {
              return _vm.asignDefaultFieldMapping()
            },
          },
        },
        [_vm._v(" Auto Map Columns ")]
      ),
      _vm._l(_vm.fileData.headers, function (header, columnIndex) {
        return _c(
          "v-card",
          { key: columnIndex, staticClass: "my-3", attrs: { outlined: "" } },
          [
            _c("v-card-title", [
              _vm._v("Matching column for " + _vm._s(header)),
            ]),
            _c(
              "v-card-text",
              [
                _c(
                  "v-container",
                  [
                    _vm.requiredFieldsLeft.length > 0
                      ? _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "v-alert",
                              { attrs: { type: "info", text: "", dense: "" } },
                              [
                                _vm._v(" Remaining required fields: "),
                                _vm._l(
                                  _vm.requiredFieldsLeft,
                                  function (field) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: field,
                                        staticClass: "mx-1",
                                        attrs: {
                                          color: "primary",
                                          outlined: "",
                                          small: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.columnValues[header] = field
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(field))]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mb-3" },
                      _vm._l(
                        _vm.mappingErrors[header],
                        function (error, index) {
                          return _c(
                            "v-alert",
                            {
                              key: index,
                              attrs: { type: "error", text: "", dense: "" },
                            },
                            [_vm._v(" " + _vm._s(error) + " ")]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "7" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.sortedFields,
                                label: header,
                                hint: "Select a column or column will be ignored",
                                "persistent-hint": "",
                                outlined: "",
                                dense: "",
                              },
                              model: {
                                value: _vm.columnValues[header],
                                callback: function ($$v) {
                                  _vm.$set(_vm.columnValues, header, $$v)
                                },
                                expression: "columnValues[header]",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _c("div", { staticClass: "preview" }, [
                            _c("table", [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "greyed-cell" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(header) +
                                        " > " +
                                        _vm._s(_vm.columnValues[header]) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.fileData.items.slice(0, 5),
                                  function (item, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _vm._v(
                                          " " + _vm._s(item[columnIndex]) + " "
                                        ),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _vm.isNextDisabled
            ? _c(
                "p",
                { staticClass: "mb-0 mr-2 error--text text-caption" },
                [
                  _c("v-icon", { attrs: { small: "", color: "error" } }, [
                    _vm._v("mdi-alert"),
                  ]),
                  _vm._v(
                    " The next button is disabled because some required fields are missing or something is wrong with the mapping."
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isNextDisabled ? _c("v-spacer") : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: { color: "error", loading: _vm.loading.cancel },
              on: {
                click: function ($event) {
                  _vm.showConfirm = true
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              on: {
                click: function ($event) {
                  return _vm.$emit("close-dialog")
                },
              },
            },
            [_vm._v("Close")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: {
                disabled: _vm.isNextDisabled,
                color: "#00aadb",
                loading: _vm.loading.next,
              },
              on: { click: _vm.confirmMapping },
            },
            [_vm._v(" Next ")]
          ),
          _c("ActionConfirmation", {
            attrs: {
              text: "Are you sure? Your upload progress will be lost.",
              "button-text": "cancel upload",
              "button-color": "error",
              open: _vm.showConfirm,
            },
            on: {
              back: function ($event) {
                _vm.showConfirm = false
              },
              confirm: _vm.cancel,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }