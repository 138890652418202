import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "../auth/authGuard";
import IntegrationsSetup from "../views/IntegrationsSetup.vue";
import Migration from "../views/Migration.vue";
import MigrationHistory from "../views/MigrationHistory.vue";
import Profile from "../views/Profile.vue";
import AdminPage from "../views/AdminPage.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { path: "/setup" },
    beforeEnter: authGuard
  },
  {
    path: "/setup",
    name: "Integrations setup",
    component: IntegrationsSetup,
    beforeEnter: authGuard
  },
  {
    path: "/migration",
    name: "Migration",
    component: Migration,
    beforeEnter: authGuard
  },
  {
    path: "/history",
    name: "Migrations history",
    component: MigrationHistory,
    beforeEnter: authGuard
  },
  {
    path: "/admin",
    name: "Administration",
    component: AdminPage,
    beforeEnter: authGuard
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: authGuard
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;