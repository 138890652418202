<template>
  <div class="pa-1">
    
    <!-- Selected ID -->
    <div>
      <h2>Select the id column</h2>
      <p class="text-caption">
        You can use a column that is mapped to a specific entities. <br>
        * If you select something other than the default id column (ie. <strong>{{ defaultId }}</strong> for {{ entity }}), also <strong> {{ defaultId }}</strong> is mapped to a header in your csv the choice here will override the value in this column
      </p>
      <v-select
        v-model="selectedId"
        :items="allowedIds"
        hint="Please chose the column to use as the id"
        persistent-hint
        outlined
        dense
      >
      </v-select>
    </div>

    <v-divider class="my-8"></v-divider>

    <!-- Column mapping -->
    <h2>Map the columns</h2>

    <v-btn 
        class="white--text" 
        color="#00aadb" 
        :loading="loading.autoMapCol" 
        @click="asignDefaultFieldMapping()"
      >
        Auto Map Columns
    </v-btn>

    <v-card v-for="(header, columnIndex) in fileData.headers" :key="columnIndex" outlined class="my-3">
      <v-card-title>Matching column for {{ header }}</v-card-title>
      <v-card-text>
        <v-container>
          
          <!-- Required fields -->
          <div class="mb-3" v-if="requiredFieldsLeft.length > 0">
            <v-alert type="info" text dense>
              Remaining required fields:
              <v-chip v-for="field in requiredFieldsLeft" :key="field" class="mx-1" color="primary" outlined small @click="columnValues[header] = field">{{ field }}</v-chip>
            </v-alert>
          </div>

          <!-- Errors -->
          <div class="mb-3">
            <v-alert type="error" text dense v-for="(error, index) in mappingErrors[header]" :key="index">
              {{ error }}
            </v-alert>
          </div>

          <v-row>
            <!-- Column select -->
            <v-col cols="7">
              <v-select
                v-model="columnValues[header]"
                :items="sortedFields"
                :label="header"
                hint="Select a column or column will be ignored"
                persistent-hint
                outlined
                dense
              >
              </v-select>
            </v-col>

            <!-- Column preview -->
            <v-col cols="5">
              <div class="preview">
                <table>
                  <thead>
                    <tr>
                      <th class="greyed-cell">
                        {{ header }} > {{ columnValues[header] }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) of fileData.items.slice(0, 5)"
                      :key="index"
                    >
                      <td>
                        {{ item[columnIndex] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Footer -->
    <div class="d-flex justify-end">
      <p class="mb-0 mr-2 error--text text-caption" v-if="isNextDisabled"><v-icon small color="error">mdi-alert</v-icon> The next button is disabled because some required fields are missing or something is wrong with the mapping.</p>
      <v-spacer v-if="isNextDisabled"></v-spacer>
      <v-btn 
        color="error" 
        :loading="loading.cancel" 
        @click="showConfirm = true"
      >
        Cancel
      </v-btn>
      <v-btn class="mx-2" @click="$emit('close-dialog')">Close</v-btn>
      <v-btn 
        :disabled="isNextDisabled" 
        class="white--text" 
        color="#00aadb" 
        :loading="loading.next" 
        @click="confirmMapping"
      >
        Next
      </v-btn>
      <ActionConfirmation
        text="Are you sure? Your upload progress will be lost."
        button-text="cancel upload"
        button-color="error"
        :open="showConfirm"
        @back="showConfirm = false"
        @confirm="cancel"
      />
    </div>
  </div>
</template>

<script>
import { getSchema, validateCsv } from "../../../service/csvIntegrationApi";
import { updateCsvInfo } from "../../../database/model/model.migration_csv_info";
import { sortAlphaNum } from "../../../service/helpers";
import ActionConfirmation from "../../ui/ActionConfirmation.vue"

export default {
  name: "ColumnMatch",
  components: { ActionConfirmation },
  props: { entity: String, fileData: Object, filesRequired: Array },
  data() {
    return {
      defaultId: null,
      selectedId: null,
      allowedIds: [],
      fields: [],
      columnValues: {},
      incrementingFields: [],
      schema: {},
      showConfirm: false,
      loading: {
        cancel: false,
        next: false,
        autoMapCol: false,
      },
    }
  },
  computed: {
    // Return the name of the selected destination integration
    destinationIntegrationName() {
      const state = this.$store.state.firestore;
      const destinationIntegration = state.integrations.find(integration => integration.id === state.user.last_migration.destination);
      return destinationIntegration.name;
    },
    // Return migration ID of the current migration
    migrationId() {
      return this.$store.state.firestore.user.last_migration.migration;
    },
    // Return a sort fields array and add a ignore option as the first element
    sortedFields() {
      const unsortedFields = [...this.fields]
      unsortedFields.sort(sortAlphaNum);
      const result = unsortedFields.map(field => {
        return {
          text: field,
          value: field,
          disabled: Object.values(this.columnsMapping).includes(field)
        }
      });
      result.unshift({ text: "-- Ignore column --", value: null });
      return result;
    },
    // Return a filtered column mapping without the ignored ones
    columnsMapping() {
      const result = {};
      Object.keys(this.columnValues).forEach(column => {
        if (this.columnValues[column]) result[column] = this.columnValues[column];
      });
      return result
    },
    // Return a list of the non selected required fields
    requiredFieldsLeft() {
      const selectedFields = [];
      Object.keys(this.columnsMapping).forEach(column => {
        selectedFields.push(this.columnsMapping[column]);
      });

      const requiredFieldsLeft = [];
      for (const field in this.schema.fields) {
        if (this.schema.fields[field].required && !selectedFields.includes(field)) requiredFieldsLeft.push(field);
        if (this.schema.fields[field].variation.min_required) {
          for (let i = 1; i <= this.schema.fields[field].variation.min_required; i++) {
            const numberedField = `${field}_${i}`
            if (!selectedFields.includes(numberedField)) requiredFieldsLeft.push(numberedField);
          }
        }
      }
      if (!selectedFields.includes(this.selectedId)) requiredFieldsLeft.push(this.selectedId);
      return requiredFieldsLeft;
    },
    // Return if the button next should be disabled
    isNextDisabled() {
      return this.selectedId === null || this.requiredFieldsLeft.length > 0 || Object.keys(this.mappingErrors).length > 0;
    },
    // Return an object of the available incrementing fields and a list of the numbers available
    availableIncrementingFields() {
      const result = {};

      this.fields.forEach(field => {
        this.incrementingFields.forEach(incrementingField => {
          if (field.includes(incrementingField)) {
            const splittedField = field.split("_");
            const number = Number(splittedField.pop());
            const fieldWithoutNumber = splittedField.join("_");

              if (this.schema.fields[fieldWithoutNumber].variation.has_variation) {
                if (result[fieldWithoutNumber]) result[fieldWithoutNumber].push(number)
                else result[fieldWithoutNumber] = [number]
              }
            }
          })
      })

      return result
    },
    // Return an object of the selected/mapped incrementing fields and a list of the numbers selected
    selectedIncrementingValues() {
      const result = {};
      for (const column in this.columnsMapping) {
        if (this.columnsMapping[column].includes("_")) {
          const splittedField = this.columnsMapping[column].split("_");
          const number = Number(splittedField.pop());
          const fieldWithoutNumber = splittedField.join("_");
          if (this.incrementingFields.includes(fieldWithoutNumber)) {
            if (result[fieldWithoutNumber]) {
              result[fieldWithoutNumber].push(number);
              } else {
              result[fieldWithoutNumber] = [number];
            }
          }
        }
      }
      return result;
    },
    // Return a list of errors in the mapping
    mappingErrors() {
      const errors = {};
      // Check if the selected incrementing values are following each other (1, 2, 3...)
      for (const field in this.selectedIncrementingValues) {
        const highestNumber = Math.max(...this.selectedIncrementingValues[field]);
        for (let number = 2; number <= highestNumber; number++) {
          if (!this.selectedIncrementingValues[field].includes(number - 1)) {
            const wrongHeader = Object.keys(this.columnsMapping).find(header => this.columnsMapping[header] === `${field}_${number}`)
            if (errors[wrongHeader]) {
              errors[wrongHeader].push(`You can't select ${field}_${number} without selecting ${field}_${number - 1}`);
            } else {
              errors[wrongHeader] = [`You can't select ${field}_${number} without selecting ${field}_${number - 1}`];
            }
          }
        }
      }

      return errors;
    }
  },
  methods: {
    asignDefaultFieldMapping(){
      this.loading.autoMapCol = true;
      this.fileData.headers.forEach(csvHeader => {
        const validSortedField = this.sortedFields.find( fieldToMap => fieldToMap.value === csvHeader)
        if (validSortedField) {
          validSortedField.disabled = true;
          this.columnValues[csvHeader] = csvHeader
        }
      });
      this.loading.autoMapCol = false;
    },
    // Fetch schema from api
    async getSchema() {
      try {
        const res = await getSchema(this.destinationIntegrationName, this.entity);
        return res.data;
      } catch (error) {
        console.error(error);
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please reload the page." });
      }
    },
    // Build the fields array for the mapping options in the select menu and build the list of incrementing fields
    async buildFields() {
      this.schema = await this.getSchema();
      this.defaultId = this.schema.app_id_col;
      this.selectedId = this.defaultId;
      for (const field in this.schema.fields) {
        if (this.schema.fields[field].active) {
          if (this.schema.fields[field].allow_as_id) this.allowedIds.push(field);
          if (this.schema.fields[field].variation.has_variation) {
            this.incrementingFields.push(field);
            this.fields.push(field + "_1");
            if (this.schema.fields[field].variation.min_required > 1) {
              for (let i = 2; i <= this.schema.fields[field].variation.min_required; i++) {
                this.fields.push(`${field}_${i}`);
              }
            }
          } else {
            this.fields.push(field);
          }
        }
      }
    },
    // Update the column mapping of the csv_info in firebase
    updateMapping() {
        const payload = {
          user_id: this.$store.state.firestore.id,
          migration_id: this.migrationId,
          entity_name: this.entity,
          updated_csv_info: {
            columns_mapping: this.columnsMapping,
            chosen_id: this.selectedId
          }
        };
        return updateCsvInfo(payload);
    },
    // Confirm the csv file
    confirmCsv() {
      const payload = {
        integration_name: this.destinationIntegrationName,
        migration_id: this.migrationId
      }
      return validateCsv(this.entity, payload);
    },
    // Update mapping then confirm file
    async confirmMapping() {
      this.loading.next = true;
      try {
        await this.updateMapping();

        const res = await this.confirmCsv();
        const { validated_path, error_path } = res.data;
        this.$emit('next', { validated_path, error_path });
      } catch (error) {
        console.error(error);
        this.$store.dispatch("setSnackbar", { type: "error", text: "Something went wrong. Please try again." });
      } finally {
        this.loading.next = false;
      }
    },
    // Cancel upload and delete files
    cancel() {
      this.showConfirm = false;
      this.loading.cancel = true;
      this.$emit("cancel", () => this.loading.cancel = false);
    }
  },
  watch: {
    // Add the headers name as keys of columnValues
    fileData(data) {
      if (Object.keys(data).length > 0) {
        data.headers.forEach(header => this.$set(this.columnValues, header, null));
        this.buildFields();
      }
    },
    // Remove or add back defaultId from fields depending on selectedId
    selectedId(id) {
      if (id !== this.defaultId) {
        const indexOfDefaultId = this.fields.indexOf(this.defaultId);
        this.fields.splice(indexOfDefaultId, 1);

        const columnMappedWithDefaultId = Object.keys(this.columnsMapping).find(header => this.columnsMapping[header] === this.defaultId);
        if (columnMappedWithDefaultId) this.columnValues[columnMappedWithDefaultId] = null;
      } else if (!this.fields.includes(this.defaultId)) {
        this.fields.push(this.defaultId)
      }
    },
    // Check for each incrementing field if it needs to be incremented
    selectedIncrementingValues(selectedIncrementingValues) {
      Object.keys(selectedIncrementingValues).forEach(field => {
        let increment = true;

        this.availableIncrementingFields[field].forEach(number => {
          if (!selectedIncrementingValues[field].includes(number)) increment = false
        })

        if (increment) {
          const highestNumber = Math.max(...this.availableIncrementingFields[field]);
          if (highestNumber < this.schema.fields[field].variation.max) this.fields.push(`${field}_${highestNumber + 1}`)
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.v-card__title {
  padding-bottom: 0;
}
.v-card__text {
  padding-bottom: 0;
}
.preview {
  overflow-x: auto;
  white-space: nowrap;
  table {
    width: 100%;

    .greyed-cell {
      background-color: rgb(233, 233, 233);
    }

    th {
      padding: 0 16px;
      border: thin solid rgba(0, 0, 0, 0.12);
      height: 25px;
    }

    td {
      border: thin solid rgba(0, 0, 0, 0.12);
      padding: 0 16px;
      height: 25px;
    }
  }
}

</style>