<template>
    <v-data-table
        :headers="headers"
        :items="fetchitems"
        :loading="loading"
        loading-text="Loading..."
        hide-default-footer
        class="extended-table"
    >
        <template v-slot:item.fetch="{ item }">
            {{ item.fetch.count_done }} / {{ item.fetch.count_total }}
            <span class="errors">({{ item.fetch.count_error }} error(s))</span>
        </template>
        <template v-slot:item.convert="{ item }">
            {{ item.convert.count_done }} / {{ item.convert.count_total }}
            <span class="errors"
                >({{ item.convert.count_error }} error(s))</span
            >
        </template>
        <template v-slot:item.push="{ item }">
            {{ item.push.count_done }} / {{ item.push.count_total }}
            <span class="errors">({{ item.push.count_error }} error(s))</span>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "MigrationsDetails",
    props: {
        items: Array,
        loading: Boolean,
    },
    watch: {
        items() {
            this.fetchitems = this.items;
        },
    },
    created() {
        this.fetchitems = this.items;
    },
    data() {
        return {
            fetchitems: [],
            headers: [
                {
                    text: "Entity",
                    value: "entity_name",
                    align: "start",
                    sortable: "true",
                },
                {
                    text: "Fetch",
                    value: "fetch",
                    sortable: false,
                },
                {
                    text: "Convert",
                    value: "convert",
                    sortable: false,
                },
                {
                    text: "Push",
                    value: "push",
                    sortable: false,
                },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
.extended-table {
    background-color: #f5f5f5;

    .errors {
        font-size: 0.7rem;
    }
}
</style>
