import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'doosyncv3',
  storage: window.localStorage
});


const store = new Vuex.Store({
  state: {
    showWelcome: true,
    oauth_urls: {},
    firestore:{
      id: null,
      user: null,
      integrations: null,
      migration: null,
      historical_migrations: null
    },
    snackbar: {
      show: false,
      text: "",
      type: "",
    }
  },
  mutations: {
    SET_FIRESTORE_INTEGRATIONS(state, payload) {
      state.firestore.integrations = payload;
    },
    SET_FIRESTORE_MIGRATION(state, payload) {
      state.firestore.migration = payload;
    },
    SET_ID(state, id) {
      state.firestore.id = id
    },
    SET_FIRESTORE_USER(state, payload) {
      state.firestore.user = payload;
    },
    WELCOME_CLICKED(state) {
      state.showWelcome = false;
    },
    SET_OAUTH_URLS(state, payload) {
      state.oauth_urls = payload;
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload;
    },
    SET_MIGRATION_DESTINATION(state, migration_id) {
      state.firestore.user.last_migration.destination = migration_id
    },
    SET_MIGRATION_SOURCE(state, migration_id) {
      state.firestore.user.last_migration.source = migration_id
    }
  },
  actions: {
    setUserID(context, id) {
      context.commit("SET_ID", id);
    },
    setFirestoreUser(context, user) {
      context.commit("SET_FIRESTORE_USER", user);
      // if (user.last_migration.migration) {
        
      // }
    },
    setFirestoreIntegrations(context, firestore_integrations) {
      context.commit("SET_FIRESTORE_INTEGRATIONS", firestore_integrations);
    },
    setFirestoreMigration(context, migration) {
      context.commit("SET_FIRESTORE_MIGRATION", migration);
    },
    welcomeClicked(context) {
      context.commit("WELCOME_CLICKED");
    },
    setOAuthURLs(context, urlsObject) {
      context.commit("SET_OAUTH_URLS", urlsObject)
    },
    setMigrationDestination(context, destinationInfo) {
      context.commit("SET_MIGRATION_DESTINATION", destinationInfo ? destinationInfo.id : null)
    },
    setMigrationSource(context, sourceInfo) {
      context.commit("SET_MIGRATION_SOURCE", sourceInfo ? sourceInfo.id : null)
    },
    setSnackbar({ commit, state }, info) {
      commit("SET_SNACKBAR", info === "close" ? { ...state.snackbar, show: false } : { ...info, show: true })
    }
  },
  modules: {},
  plugins: [vuexPersist.plugin]
});

export default store;