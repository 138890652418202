var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showAdmin()
        ? _c(
            "div",
            [
              _c(
                "v-card",
                { staticClass: "center-flex" },
                [
                  _c("v-card-title", [_vm._v("Placeholder")]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: { label: "Registration key" },
                        model: {
                          value: _vm.newRegistrationKey,
                          callback: function ($$v) {
                            _vm.newRegistrationKey = $$v
                          },
                          expression: "newRegistrationKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "#00aadb", loading: _vm.fetchKeyLoading },
                      on: { click: _vm.getNewKey },
                    },
                    [_vm._v("Generate a registration key")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("v-container", { staticClass: "center-flex" }, [
            _c("h1", [_vm._v("Unauthorized Access")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }