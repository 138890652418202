import axios from "axios"
import { getAuth, signInWithCustomToken } from "firebase/auth";

export class DoosyncMainApi {
    constructor(auth_instance) {
        this.api_url = process.env.VUE_APP_MIGRATION_URL
        this.auth_instance = auth_instance
    }

    async getFirebaseToken() {
        const token = await this.auth_instance.getTokenSilently()
        const res = await axios.get(`${this.api_url}/firebase/token`, { headers: { "Authorization": `bearer ${token}` } })

        return res.data
    }

    async getFirebaseCredential() {
        let data = await this.getFirebaseToken()
        let auth = getAuth()
        let userCredential = await signInWithCustomToken(auth, data['firebase_token'])

        return userCredential
    }

    async getMe() {
        /**
         * 	Response body
         *   {
         *       "email": "email@exemple.com",
         *       "id": " 97tv681g0xh29mx311"
         *   }
         */
        const token = await this.auth_instance.getTokenSilently()
        const res = await axios.get(`${this.api_url}/firebase/me`, { headers: { "Authorization": `bearer ${token}` } })
        return res.data
    }

    async getOAuthUrl(name, extras= null){
        const token = await this.auth_instance.getTokenSilently();
        let endpointToCall = `${this.api_url}/auth/get_url/${name}`
        if (extras){
            endpointToCall = `${endpointToCall}?extras=${extras}`
        }
        const url = axios.get(endpointToCall, { headers: {"Authorization": `bearer ${token}` }});

        return url;
    }

    async postMigrationAction(type, migration_id) {
        const token = await this.auth_instance.getTokenSilently();
        return axios.post(`${this.api_url}/migration/action/${type}`, { migration_id }, { headers: { "Authorization": `bearer ${token}` } });
    }

    async createIntegration(integration_type) {
        const token = await this.auth_instance.getTokenSilently();
        return axios.post(`${this.api_url}/integration/${integration_type}`, {}, { headers: { "Authorization": `bearer ${token}` } });
    }

    async deleteIntegration(type, integration_id) {
        const token = await this.auth_instance.getTokenSilently();
        return axios.delete(`${this.api_url}/integration/${type}/${integration_id}`, { headers: { "Authorization": `bearer ${token}` } });
    }

    async getSerial() {
        const token = await this.auth_instance.getTokenSilently();
        return axios.get(`${this.api_url}/integration/serial/quickbooks`, { headers: { "Authorization": `bearer ${token}` } })
    }

    async createSerial() {
        const token = await this.auth_instance.getTokenSilently();
        return axios.post(`${this.api_url}/integration/serial/quickbooks`, {}, { headers: { "Authorization": `bearer ${token}` } })
    }

    async createRegistrationKey(authorized_roles, contact=null) {
        const token = await this.auth_instance.getTokenSilently();
        return axios.post(
            `${this.api_url}/signup/registration_key`, 
            {"authorized_roles":authorized_roles, "contact": contact}, 
            { 
                headers: { "Authorization": `bearer ${token}` } 
            }
        )
    }

}


