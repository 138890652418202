<template>
  <div>
    <v-divider class="my-5"></v-divider>

    <v-row>
      <v-col cols="6">
        <v-select 
          outlined
          dense
          max-width="300"
          hint="If your version is not in the list, contact our support"
          persistent-hint
          v-model="version.selected"
          :items="version.list"
        >
        </v-select>
      </v-col>

      <v-col cols="6">
        <v-btn>
          <v-icon class="mr-1">mdi-download</v-icon>Download extraction app
        </v-btn>
      </v-col>
    </v-row>

    <v-btn 
      class="white--text mt-3" 
      color="#00aadb" 
      :loading="loading" 
      :disabled="showSerialInfo"
      @click="getSerial"
    >
      Generate my serial
    </v-btn>

    <QuickbooksSerial v-if="showSerialInfo" :serial-info="serialInfo" />
  </div>
</template>

<script>
import { getInstance } from "../../auth";
import { DoosyncMainApi } from "../../service/main_api";
import QuickbooksSerial from "./quickbooks/QuickbooksSerial.vue"

export default {
  name: "QuickbooksSetup",
  components: { QuickbooksSerial },
  data() {
    return {
      version: {
        list: [
          { value: 19,  text: "Quickbooks version 19" },
        ],
        selected: 19,
      },
      serialInfo: {},
      loading: false,
    }
  },
  methods: {
    async getSerial() {
      try {
        this.loading = true;
        const api = new DoosyncMainApi(getInstance());
        const res = await api.getSerial();
        this.serialInfo = res.data.data
        this.$store.dispatch("setSnackbar", { type: "success", text: `Serial generated.` })
      } catch (error) {
        this.$store.dispatch("setSnackbar", { type: "error", text: `Something went wrong. Try again.` })
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    showSerialInfo() {
      return Object.keys(this.serialInfo).length > 0
    }
  }
}
</script>

<style>

</style>