<template>
  <div>
    <v-divider class="mt-4"></v-divider>
    <v-card elevation="0">
      <!-- Title -->
      <v-card-title class="subtitle-1">Manage your locations for the csv files:</v-card-title>

      <!-- Location name text input -->
      <v-card-text class="pb-0">
        <v-form v-model="valid">
          <div class="d-flex" v-for="(location, index) in locations" :key="index">
            <v-text-field 
              class="mr-2 shrink"
              label="Location ID"
              v-model="location.id"
              :rules="[rules.required, checkDuplicate(index)]"
              :disabled="!editable"
              outlined
              dense
            >
            </v-text-field>
            <v-text-field 
              label="Location name" 
              v-model="location.name" 
              :disabled="!editable"
              :rules="[rules.required]"
              outlined 
              dense
            >
              <template v-slot:append-outer>
                <v-btn icon :disabled="locations.length === 1" @click="changeLocations(index)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
            </v-text-field>
          </div>
        </v-form>
      </v-card-text>

      <!-- Add / Save / Edit buttons -->
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <div v-if="editable">
          <v-btn @click="changeLocations(null)" class="mr-3" small><v-icon class="mr-1" small>mdi-plus</v-icon>Add a location</v-btn>
          <v-btn class="white--text" color="#00aadb"  small :loading="loading" @click="save" :disabled="!valid"><v-icon small class="mr-1">mdi-content-save</v-icon>Save locations settings</v-btn>
        </div>
        <v-btn v-else @click="editable = true" small><v-icon small class="mr-1">mdi-pencil</v-icon>Edit locations</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { updateIntegration } from "../../database/model/model.integration";
import { generateRandomId } from "../../service/helpers";

export default {
  name: "CsvLocations",
  data() {
    return {
      locations: [{ 
        id: "1",
        name: "",
        country: null,
        currency_code: null,
        timezone: null
      }],
      editable: false,
      valid: true,
      rules: {
          required: value => !!value || 'Required.',
      },
      loading: false,
    }
  },
  computed: {
    // Return the csv integration object
    csvIntegration() {
      return this.$store.state.firestore.integrations.find(integration => integration.name === "csv")
    },
    // Return the tax_settings objext if it exists
    csvTaxSettings() {
      const locationWithTaxSettings = this.csvIntegration.locations.find(location => location.tax_settings)
      return locationWithTaxSettings ? locationWithTaxSettings.tax_settings : null;
    },
    // Return the locations array with the tax_settings being added
    locationsWithTaxSettings() {
      if (this.csvTaxSettings) {
      return this.locations.map(location => {
        return {
            ...location,
            tax_settings: this.csvTaxSettings
          }
        })
      } else {
        return this.locations
      }
    },
    existingIds() {
      return this.locations.map(loc => loc.id)
    }
  },
  methods: {
    // Set locations to their default/initial values
    setLocations() {
      if (this.csvIntegration.locations.length > 0) {
        this.locations = [ ...this.csvIntegration.locations ];
        this.editable = false;
      } else {
        this.editable = true;
      }
    },
    // Add or delete location in the data
    changeLocations(index) {
      if (Number.isInteger(index)) {
        this.locations.splice(index, 1);
      } else {
        // TODO: Why was it doing this (create abug that disable adding location when the tax are not set) ? else if (this.csvTaxSettings) { 
        this.locations.push({ 
          id: generateRandomId(this.existingIds),
          name: "",
          country: null,
          currency_code: null,
          timezone: null,
        });
      }
    },
    // Update the csv integration in firebase and in the state with the new locations
    async save() {
      this.loading = true;
      try {
        const firestore = this.$store.state.firestore;

        const { id: integrationId } = this.csvIntegration;
        await updateIntegration(firestore.id, integrationId, { locations: [...this.locationsWithTaxSettings] });

        const newCsvIntegration = { ...this.csvIntegration, locations: [...this.locationsWithTaxSettings] };
        const allIntegrations = [ ...this.$store.state.firestore.integrations.filter(integration => integration.name !== "csv"), newCsvIntegration ];
        this.$store.dispatch("setFirestoreIntegrations", allIntegrations);

        this.editable = false;
        this.$store.dispatch("setSnackbar", { type: "success", text: `Locations saved.` })
      } catch (error) {
        console.log("save, error ->", error);
        this.$store.dispatch("setSnackbar", { type: "error", text: `Something went wrong. Please try again.` });
      } finally {
        this.loading = false;
      }
    },
    // Check if ID already exists among other IDs
    checkDuplicate(index) {
      const value = this.locations[index].id;
      const otherIds = [...this.existingIds.slice(0, index), ...this.existingIds.slice(index + 1)];
      if (otherIds.includes(value)) {
        return "ID already exists.";
      } else {
        return true;
      }
    }
  },
  created() {
    this.setLocations();
  }
}
</script>

<style scoped>

</style>