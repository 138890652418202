var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { dark: "", app: "", "mobile-breakpoint": "834" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { staticClass: "nav-button-container" }, [
        _c("div", [
          _c("img", {
            staticClass: "toolbar-branding d-flex pa-3",
            attrs: {
              src: "https://dohn7qwk9v0u7.cloudfront.net/branding/2018/png/logo-doosync-rgb.ai-blue.png",
            },
          }),
          _c(
            "p",
            {
              staticClass:
                "white--text text-caption font-italic font-weight-thin pl-3",
            },
            [_vm._v(_vm._s(_vm.$auth.user.email))]
          ),
          _c(
            "div",
            { staticClass: "mt-10" },
            [
              _c(
                "v-btn",
                { attrs: { depressed: "", block: "", tile: "", to: "/setup" } },
                [_c("v-icon", [_vm._v("mdi-cogs")]), _vm._v(" Setup ")],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    block: "",
                    tile: "",
                    to: "/migration",
                  },
                },
                [
                  _c("v-icon", [_vm._v("mdi-play-pause")]),
                  _vm._v(" Migration "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { depressed: "", block: "", tile: "", to: "/history" },
                },
                [
                  _c("v-icon", [_vm._v("mdi-clipboard-text-clock")]),
                  _vm._v(" Migrations history "),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          [
            _vm.showAdmin()
              ? _c(
                  "v-btn",
                  {
                    attrs: { depressed: "", block: "", tile: "", to: "/admin" },
                  },
                  [
                    _c("v-icon", [_vm._v("mdi-account")]),
                    _vm._v(" Admin Page "),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-btn",
              { attrs: { depressed: "", block: "", tile: "", to: "/profile" } },
              [_c("v-icon", [_vm._v("mdi-account")]), _vm._v(" Profile ")],
              1
            ),
            _c(
              "v-btn",
              {
                attrs: { block: "", depressed: "", tile: "" },
                on: { click: _vm.logout },
              },
              [_c("v-icon", [_vm._v("mdi-lock")]), _vm._v(" Logout ")],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }