var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setup d-flex flex-column pa-8" },
    [
      _c("DisabledAlert", { attrs: { show: _vm.disablePage } }),
      _c("IntegrationSelection", {
        attrs: {
          type: "destination",
          list: _vm.availableIntegrations.destination,
          disabled: _vm.disablePage,
        },
      }),
      _c("v-icon", { attrs: { size: "80", disabled: _vm.disablePage } }, [
        _vm._v("mdi-arrow-up-bold"),
      ]),
      _c("IntegrationSelection", {
        attrs: {
          type: "source",
          list: _vm.availableIntegrations.source,
          "is-destination-selected": _vm.isDestinationSelected,
          disabled: _vm.disablePage || !_vm.isDestinationSelected,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }